.qa-checklist {
    border: none;
    grid-template-columns: 23% 77%;
}

.main-checklist .input-outer-container, .main-checklist .input-label, .main-checklist .input-inner-container {border: 1px solid rgba(0, 0, 0, 20%);}

.main-checklist .form-field {
    display: grid;
    grid-template-columns: 100%;
}

.main-checklist .input-outer-container {
    display: grid;
    grid-template-columns: 45% 55%;
    margin: 0 0 20px 0;
}

.main-checklist .input-label {
    padding: 9px 5px;
    font-weight: 400 !important;
    min-height: 30px;
}

.items-checklist .input-inner-container { margin-bottom: 0; }

.input-outer-container .input-inner-container {
    border-radius: 0;
}

.green-tick::after, .red-cross::after {
    height: 36px;
    width: 36px;
    margin: 0 0 0 10px;
}

.red-cross::after, .items-checklist table tbody tr td[class*='valid'] {
    content: var(--error-highlight-icon);
}

.green-tick::after, .items-checklist table tbody tr td.valid-true {
  content: var(--valid-highlight-icon);
}

.qa-checklist .warningHighlight, .qa-checklist-modal .warningHighlight {
    background-color: var(--warning-highlight-color);
}

.qa-checklist .invalidHighlight, .qa-checklist-modal .invalidHighlight {
    background-color: var(--error-highlight-color);
}

.qa-checklist .btn_submitted_copy_ref {
    height: 12px;
    width: 12px;
    margin-right: 5px;
}

.customs-duties {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.items-checklist table tbody tr td[class*='valid'] {
    height: 40px !important;
    width: 52px !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.items-checklist table tbody tr td[class*='valid'] > input {
    display: none;
}

.items-checklist table tbody tr td.item {width: 3% !important;}

.invoice-customer, 
.invoice-customer .input-outer-container ,
.invoice-customer .input-outer-container label, 
.invoice-customer .input-outer-container .input-inner-container {
    border-bottom: none;
    margin-bottom: 0;
}

.qa-checklist .form-field .input-label, .qa-checklist .form-field input, .form-field select, .form-field textarea {padding-left: 5px;}

.qa-checklist .btn_submitted_copy_ref img {width: 25px;}

/* Temp fix to remove the filters & pagination */
.qa-checklist .items-checklist table + div {display: none !important;}

/* Check Modal */
.checkmodal-container{
    padding: 15px;
}

.checkmodal-container__total_value{
    text-align: right;
    font-weight: 700;
    margin: 0;
    font-size: 12px;
}

.checkmodal-container__origins{
    font-weight: 700;   
}

.checkmodal-container__data_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
}

.checkmodal-container__info{
    margin: 0;
}

.checkmodal-container__select{
    border: 1px solid black;
    border-radius: 10px;
    padding: 3px 10px;
    height: 30px;
}

.checkmodal-container__select:focus{
    outline: none;
    box-shadow: none;
    border-radius: 10px;
}

.checkmodal-container__disabled_rex_validation_link{
    pointer-events: none;
    cursor: not-allowed;
}

.checkmodal-container__disabled_element{
    color: var(--eori-grey);
}