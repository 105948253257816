.footer {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.7rem 0;
  gap: 7rem;
}

.footer-icons {
  
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.footer-icons > * {
  color: white;
  transition: color 0.15s ease-in;
}

.footer-icons > *:hover, .focus > *:focus {
  color: #ffbe00;
}

.footer-text {
  font-weight: 400;
  color: rgb(212 212 212);
  font-size: 14px;
}

.footer a:hover {
  color: #ffbe00;
}

.footer a {
  /* color: #fff; */
}

