.commodity-table {
    border-collapse: collapse;
  }
  
  .commodity-table:focus {
    box-shadow: none;
  }
  
  .commodity-table-headers1 {
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .commodity-table-header1 {
    background-color: #e4e3ed;
    display: flex;
    gap: 0.5rem;
    justify-content: left;
    align-items: left;
    font-size: 1rem;
    margin: 0;
    padding: 0.75rem 0rem 0.75rem 0.3rem;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
    cursor: default;
    use-select: none;
    text-align: center;
    border-bottom: thin solid hsl(0, 0%, 80%);
    /* text-transform: uppercase; */
  }
  
  .commodity-table > *:not(:last-child) > * {
    border-right: thin solid hsl(0, 0%, 93%);
    border-left: thin solid transparent;
  }
  
  .commodity-table > *:first-child > *:first-child {
    border-top-left-radius: 20px;
  }
  
  .commodity-table > *:last-child > *:first-child {
    border-top-right-radius: 20px;
  }
  
  .commodity-table > *:first-child > *:last-child {
    border-bottom-left-radius: 20px;
  }
  
  .commodity-table > *:last-child > *:last-child {
    border-bottom-right-radius: 20px;
  }
  
  .commodity-table > * > *:not(.commodity-table-header) {
    border-bottom: thin solid hsl(0, 0%, 80%);
    border-top: thin solid transparent;
  }
  
  .commodity-table > * > *:not(.file-cell):not(.commodity-table-header) {
    /* background-color: white; */
  }
  
  .commodity-table .cell {
    padding: 0 0.2rem;
    height: 1.6em !important;
    min-height: 1.6em !important;
    max-height: 1.6em !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: cell;
    width: 100%;
  }
  
  .commodity-table .file-cell {
    height: 1.6em !important;
    min-height: 1.6em !important;
    max-height: 1.6em !important;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
  
  .commodity-table .cell[data-error], input[data-error], select[data-error] {
    position: relative;
    z-index: 20;
    box-shadow: 0 0 0px 1px rgb(253 183 183);
    background-color: rgb(245, 237, 237);
  }
  
  .commodity-input-row input[data-error]:focus, .commodity-input-row select[data-error]:focus {
    box-shadow: 0 0 0px 2px rgba(255 20 20 / 70%)!important;
    position: relative;
    z-index: 25;
  }
  
  .commodity-input-row input:focus, .commodity-input-row select:focus {
    box-shadow: 0 0 0 2px rgb(77 56 255), 0 2px 8px 5px rgb(0 0 0 / 20%);
    position: relative;
    z-index: 25;
  }
  
  .cell.last, .last-row {
    mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.9)), to(rgba(0,0,0,0)));
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.9)), to(rgba(0,0,0,0)));
    cursor: pointer;
  }
  
  .last-row { 
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .selection-overlay {
    position: absolute;
  
    background-color: rgba(0,0,0,0.05);
    box-shadow: 0 0 0 1px rgb(117 109 183) !important;
    box-sizing: border-box;
    z-index: 10000;
    pointer-events: none;
  }
  
  .root-overlay {
    position: absolute;
  
    /* border: 2px solid var(--eori-orange); */
    box-shadow: 0 0 0 1px rgb(117 109 183) !important;
    box-sizing: border-box;
    z-index: 10010;
    pointer-events: none;
  
    display: flex;
    align-content: stretch;
    justify-content: stretch;
  }
  
  .root-overlay.error {
    /* box-shadow: 0 0 0px 1px rgb(253 183 183); */
    /* background-color:   rgb(245, 237, 237); */
  }
  
  .root-overlay input {
    width: 100%;
    height: 100%;
    appearance: none;
    padding: 0 0.2rem;
    margin: 0;
    border: none;
    z-index: 20;
    pointer-events: auto;
  }
  
  .root-overlay input:focus {
    box-shadow: 0 0 0 2px rgb(77 56 255), 0 2px 8px 5px rgb(0 0 0 / 20%);
  }
  
  .commodity-input-row > * {
    border: thin solid transparent;
    padding: 0.1rem 0.2rem;
  }
  
  .commodity-input-row > *:not(:first-child) {
    border-left: thin solid hsl(0, 0%, 80%);
    border-right: thin solid transparent;
  }
  
  .commodity-input-row:not(:first-child) > * {
    border-top: thin solid hsl(0, 0%, 80%);
    border-bottom: thin solid transparent;
  }
  
  .commodity-table-headers {
    display: flex;
    /* background-color:  */
  }
  
  .commodity-table-headers > *:not(:first-child) {
    border-left: thin solid hsl(0, 0%, 80%);
    border-right: thin solid transparent;
  }
  
  .commodity-table-headers > *:first-child > h1 {
    border-top-left-radius: 20px;
  }
  
  .commodity-table-headers > *:last-child > h1 {
    border-top-right-radius: 20px;
  }
  
  
  .right-click-menu {
    position: absolute;
    background-color: white;
    box-shadow: 0 0 4px 1px hsl(0 0% 0% / 20%);
    border-radius: 5px;
    min-width: 10rem;
    padding: 0.25rem 0;
  
    display: flex;
    flex-direction: column;
  }
  
  .right-click-menu > spacer {
    height: 1px;
    margin: 0.2rem 0;
    width: 100%;
    background-color: hsl(0 0% 0% / 20%);
  }
  
  .right-click-menu > div {
    padding: 0.1rem 3rem 0.1rem 2rem;
    width: 100%;
    background-color: white;
  }
  
  .right-click-menu > div:hover {
    background-color: hsl(0 0% 90%);
    cursor: pointer;
  }
  