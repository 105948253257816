
.floating-box-orderForm {
    background-color: #fff;
    -webkit-align-self: center;
    align-self: center;
    margin-top: 2rem;
    width: 100%;
    max-width: 768px;
    border-radius: .6rem;
}

.floating-box-header-orderForm {
    color: #0e2554; 
    border-bottom: 0;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding: 1rem;
    padding-bottom: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    margin-left: 8px;
    /* Collapse elements vertically on smaller screens */
    display: flex;
    flex-direction: column;
  }

  .inputs-group {
    max-width: 100%;
}

.report-inputs-group {
    max-width: 100%;
}


.confirmload-cell {
    background-color: var(--eori-silver);
}

#order-form {
    border-radius: 0.90rem;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-columns: 1fr 3fr 2fr 6fr;
    grid-auto-flow: row;
    grid-gap: 1px;
    grid-gap: 1px;
    gap: 1px;
    padding: 14px;
  }

/* [type="checkbox"] {
    margin-left: 13px;
} */

/* .checkbox-label, .radio-label {
    background-color: #f7dcb8 !important;
} */

.label-text {
    font-weight: 400 !important;
    color: var(--eori-blue);
    font-size: 0.875rem;
}

.port-country {
    font-size: 0.875rem;
  }

#no-order-form {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
}

#no-order-form header {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--eori-popup);
}

#no-order-form :not(header) {
    color: rgb(0 89 132);
    font-weight: 500;
    font-size: 1.2rem;
}

#orderFormToolTip {
    width: 1000px !important;
}