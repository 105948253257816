/* 
  .newload  input {
    height: 100%
  }
  .newload option {
    height: 100%
  }
 .newload select {
    height: 100%
  } */
.reportNewload {
    grid-area: main;
    display: grid;
    grid-template-columns: 20px 2fr 1fr 1fr 2fr 3fr 2fr repeat(8, 1fr) 20px ;
    grid-template-rows:  20px auto;
    grid-template-areas: 
    " . . . . . . . . . . . . . . . . "
    " . companyname companyname  goodsfrom goodsfrom goodsfrom goodsfrom goodsfrom . . . . . submit . . "
    " . name nameselect . exporter exportername . truckn truckname . . . . cc cc . "
    " . movement movmentselect . importer importername . loaddate loaddatedetails . . . .  incotermtext incotermtext . "
    " . fcl fclselect . invoice invoicetotal . packages packagesno . weights weightsdetails . incotermtext incotermtext . "
    " . truck truckselect . ead eaddetails eaddetails . . . . . . incotermtext incotermtext . "
    " . date dateselect . exs exsdetails exsdetails . . . . . . incotermtext incotermtext . "
    " . countrydespatch countrydespatchselect . tad taddetails taddetails . . . . . . incotermtext incotermtext . "
    " . countrydestination countrydestinationselect . fr frdetails frdetails . . . . . . incotermtext incotermtext . "
    " . exitport1 exitport1select . eu eudetails eudetails . . . . . . incotermtext incotermtext . "
    " . entryport1 entryport1select . gb gbdetails gbdetails . . . . . . incotermtext incotermtext . "
    " . exitport2 exitport2select . ens ensdetails ensdetails . . . . . . incotermtext incotermtext . "
    " . entryport2 entryport2select . exp1 exp1details exp1details . . . . . . incotermtext incotermtext . "
    " . office officeselect . enp1 enp1details enp1details . . . . . . incotermtext incotermtext . "
    " . jobref jobrefselect . . . . . . . . . . incotermtext incotermtext . "
    " . consignor consignorselect . .  . . . . . . . . incotermtext incotermtext . "
    " . consignee consigneeselect . traces  tracesdetails tracesdetails . . . . . . incotermtext incotermtext . "
    " . invoicenumber invoicenumberselect . ipaffs ipaffsdetails  ipaffsdetails . . . . . . incotermtext incotermtext . "
    " . invoicecurr invoicecurrselect . bcp bcpdetails . . . . . . . . . . "
    " . incoterm incotermselect . . message message . . . . . . . . . "
    " . . . . . . . . . . . . . . . . "
    " . product1select product1cc product1origin product1descriprion . . . . . . . . . . . "
    " . product2select product2cc product2origin product2descriprion . . . . . . . . . . . "
    " . product3select product3cc product3origin product3descriprion . . . . . . . . . . . "
    ;
    row-gap: 10px;
    /* font-family: "PTSansNarrowRegular", sans-serif; */
    }

      .reportNewload select {
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: hidden;
        background-color: lightgray;
      }
      .reportNewload input {
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: hidden;
        background-color: lightgray;
      }
      .cc{
          grid-area: cc;
      }
      .incotermtext{
          grid-area: incotermtext;
          display:grid;
          grid-template-areas: "strtext . .";
      }
      .stretchtext{
          grid-area: strtext;
          font-size: 90%;
      }
      .bcp {
        grid-area:bcp;
        align-self: center;
        justify-self: end;
        padding-right: 5px;
    }
    .bcpdetails {
      grid-area:bcpdetails;
      align-self: center;
        justify-self: start;
    }
      .traces {
        grid-area:traces;
        align-self: center;
        justify-self: end;
        padding-right: 5px;
    }
    .tracesdetails {
      grid-area:tracesdetails;
      align-self: center;
        justify-self: start;
    }
    .ipaffs {
        grid-area:ipaffs;
        align-self: center;
        justify-self: end;
        padding-right: 5px;
    }
    .ipaffsdetails {
      grid-area:ipaffsdetails;
      align-self: center;
        justify-self: start;
    }

      .exp1 {
        grid-area:exp1;
        align-self: center;
        justify-self: end;
        padding-right: 5px;
    }
    .exp1details {
      grid-area:exp1details;
      align-self: center;
        justify-self: start;
    }
    .enp1 {
        grid-area:enp1;
        align-self: center;
        justify-self: end;
        padding-right: 5px;
    }
    .enp1details {
      grid-area:enp1details;
      align-self: center;
        justify-self: start;
    }
  
  .companyname {
    grid-area: companyname;
    text-align: left;
    font-weight: bold;
  }
  .gb {
    grid-area:gb;
    align-self: center;
    justify-self: end;
    padding-right: 5px;
}
.gbdetails {
  grid-area:gbdetails;
  align-self: center;
    justify-self: start;
}
  .eu {
    grid-area:eu;

    align-self: center;
    justify-self: end;
    padding-right: 5px;
}
.eudetails {
  grid-area:eudetails;
  align-self: center;
    justify-self: start;
}
  .fr {
    grid-area:fr;
    align-self: center;
    justify-self: end;
    padding-right: 5px;
}
.frdetails {
  grid-area:frdetails;
  align-self: center;
    justify-self: start;
}
.ens {
    grid-area:ens;
    align-self: center;
    justify-self: end;
    padding-right: 5px;
}
.ensdetails {
  grid-area:ensdetails;
  align-self: center;
    justify-self: start;
}
  .tad {
    grid-area:tad;
    align-self: center;
    justify-self: end;
    padding-right: 5px;
}
.taddetails {
  grid-area:taddetails;
  align-self: center;
    justify-self: start;
}
  .ead {
      grid-area:ead;
      align-self: center;
      justify-self: end;
      padding-right: 5px;
  }
  .eaddetails {
    grid-area:eaddetails;
    align-self: center;
      justify-self: start;
}
.exs {
    grid-area:exs;
    align-self: center;
      justify-self: end;
      padding-right: 5px;
}
.exsdetails {
  grid-area:exsdetails;
  align-self: center;
      justify-self: start;
}
.goodsfrom{
    grid-area: goodsfrom;
    font-weight: bold;
    /* font-size: 200%; */
}

.truckn {
    grid-area: truckn;
    text-align: right;
    align-self: center;
    justify-self: end;
    padding-right: 5px;
}
.truckname {
    grid-area: truckname;
    align-self: center;
    justify-self: start;
}
.message {
    grid-area: message;
    font-size: 80%;
    font-weight: normal;
    align-self: center;
    justify-self: start;
}
.reportloaddate {
    grid-area: loaddate;
    text-align: right;
    align-self: center;
    justify-self: end;
    padding-right: 5px;
}
.loaddatedetails {
  grid-area: loaddatedetails;
  align-self: center;
  justify-self: start;
}
.exporter {
    grid-area: exporter;
}
  .reportyourName {
      grid-area: name;
      text-align: left;
  }
  .office {
    grid-area: office;
    text-align: left;
    text-decoration:none;
}
.office  a {
    text-decoration:none;
}
  .movement {
    grid-area: movement;
    text-align: left;
}
.reportnameselect {
    grid-area: nameselect;
    text-align: left;
}
.movementselect {
    grid-area: movmentselect;
    text-align: left;  
}
.auth {
    grid-area: auth;
    display: grid;
    font-size: 70%;  
}
.fcl {
    grid-area: fcl;
    text-align: left;
}

.fclselect {
    grid-area: fclselect;
    text-align: left;
}
.incoterm {
    grid-area: incoterm;
    text-align: left;
}

.incotermselect {
    grid-area: incotermselect;
    text-align: left;
}
.truck {
    grid-area: truck;
    text-align: left;
}
.truckselect {
    grid-area: truckselect;
    text-align: left;
}
.nldate{
    grid-area: date;
    text-align: left;
} 
.dateselect {
    grid-area: dateselect;
    text-align: left;
}
.countrydespatch{
    grid-area: countrydespatch;
    text-align: left;
} 
.countrydespatchselect {
    grid-area: countrydespatchselect;
    text-align: left;
}
.countrydestination {
    grid-area: countrydestination;
    text-align: left;
}
.countrydestinationselect {
    grid-area: countrydestinationselect;
    text-align: left;
}
.exitport1 {
    grid-area: exitport1;
    text-align: left;
}
.exitport1select {
    grid-area: exitport1select;
    text-align: left;
}
.entryport1 {
    grid-area: entryport1;
    text-align: left;
}
.entryport1select {
    grid-area: entryport1select;
    text-align: left;
}
.exitport2{
    grid-area: exitport2;
    text-align: left;
}
.exitport2select {
    grid-area: exitport2select;
    text-align: left;
}
.entryport2 {
    grid-area: entryport2;
    text-align: left;
}
.entryport2select {
    grid-area: entryport2select;
    text-align: left;
}

.reportjobref {
    grid-area: jobref;
    text-align: left;
}
.reportjobrefselect {
    grid-area: jobrefselect;
    text-align: left;
}
.reportconsignor {
    grid-area: consignor;
    text-align: left;
}
.reportconsignee {
    grid-area: consignee;
    text-align: left;
}
.consignorselect {
    grid-area: consignorselect;
    text-align: left;
}
.consigneeselect {
    grid-area: consigneeselect;
    text-align: left;
}
.invoicecurr {
    grid-area: invoicecurr;
    text-align: left;
}
.reportinvoicenumber {
    grid-area: invoicenumber;
    text-align: left;
}
.invoicecurrselect {
    grid-area: invoicecurrselect;
    text-align: left;
}
.reportinvoicenumberselect {
    grid-area: invoicenumberselect;
    text-align: left;

}
.newloadtextbox{
    max-width: 137px; 
}

.product1select {
    grid-area: product1select;
}
.product2select {
    grid-area: product2select;
}
.product3select {
    grid-area: product3select;
}
.product1cc {
    grid-area: product1cc;
}
.product1origin {
    grid-area: product1origin;
}
.product1descriprion {
    grid-area: product1descriprion;
}
.product2cc {
    grid-area: product2cc;
}
.product2origin {
    grid-area: product2origin;
}
.product2descriprion {
    grid-area: product2descriprion;
}
.product3cc {
    grid-area: product3cc;
}
.product3origin {
    grid-area: product3origin;
}
.product3descriprion {
    grid-area: product3descriprion;
}