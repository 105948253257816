#login-contents {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.browser {
  text-align: center;
  align-self: center;
  color: var(--eori-blue);
  font-size: 10px;
}

.login-container {
  width: 440px !important;
}

 .reset-password {
 margin-top: 2.5rem;
 margin-right: 0.4rem;
} 

.push-left-right {
  justify-content: space-between;
  display: flex;
}

.push-center {
  justify-content: center;
  display: flex;
}

.reset-container {
  display: flex;
  justify-content: center;
}

.sub-heading {
  font-weight: 400 !important;
  color: var(--eori-blue);
  font-size: 0.875rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
}

.password-error {
  color: red;
  margin-top: 20px;
}

.completed {
  content: var(--valid-highlight-icon);
}

.positions {
  background-repeat: no-repeat;
  margin: auto 34%;
}

.more-help {
  color: #0d6efd;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}