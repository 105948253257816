body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Trebuchet Ms', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family:   var(--bs-font-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: "PTSansNarrowRegular";
  src: local("PTSansNarrowRegular"),
    url("./fonts/PTSansNarrow-Regular.ttf") format("truetype");
  font-weight: normal;
} */

button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Trebuchet Ms', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}