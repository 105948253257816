
.fileloader-header {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

input[type="file"] { 
    opacity: 0; 
    z-index: -1; 
    position: absolute;
  }

.file-upload .more-info {
    font-size: 15px;
    color: var(--eori-blue);
    margin-top: 10px;
}

.file-upload .section-title {
    margin-bottom: 6px;
    font-size: 17px;
    font-weight: 500;
    margin-top: 17px;
}

.file-upload .error .section-title {
    color: rgb(255, 30, 30);
}

.file-upload .file-loader-btn {
    align-self: center;
    margin-top: 1rem;
}

.file-upload .file-loader-btn-submit {
    /* margin: 9% 0 0 17%; */
    margin: 20px auto;
}

.file-upload .more-info img, svg {
    vertical-align: bottom;
}