

.grid-edit-btn {
    color: #e6e6e3;
    background-color: rgb(28 76 105) !important;
    border-radius: 11px;
    height: 30px;
    align-content: center;
    font-weight: 400;
    display: grid;
    margin: 6px 0 0 20px;
}

.Completed {
  content: var(--valid-highlight-icon);
}

.In-Progress {
    content:url(../../images/in-progress-circle-down-arrow.svg);
}

.Failed {
    content:url(../../images/cross.svg);
}

.Submitted {
    content:url(../../images/documents.svg);
}

.edit-cell {
    color: #3938ac;
    text-align: center !important;
    border-left-width: 1px !important;
    font-family: monospace;
    font-size: 1.03rem;
}

.positions {
    background-repeat: no-repeat;
    margin: auto 30%;
}

.position-tips {
    background-repeat: no-repeat;
    width: 40px;
}

.space-evenly {
    justify-content: space-evenly;
    display: flex;
  }

  .tooltip-text {
    margin-top: 10px;
  }

  .status-button {
    color: #0e2554;
    background-color: #ffbe00;
    width: 5rem;
    border-radius: 0.9rem;
    font-weight: 600;
    line-height: 2.0rem;
    cursor: pointer;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

  .status-button.green {
    background-color: #00857d;
    color: #fff;
  }

  .status-button.green:hover {
    background-color: #007871;
    color: #ffe493;
  }

  .status-button.grey {
    background-color: var(--eori-grey) !important;
    color: #286380 !important;
    font-weight: 600;;
  }

  .status-button.light {
    background-color: #d8e9dc !important;
    color: #286380 !important;
    font-weight: 600;;
  }

  .status-button.light:hover {
    background-color: #c8e1ce !important;
  }

  .tableHeaders.status th:nth-child(n+9) {
    display:none;
  }
.auditStatus { 
  display: flex;
}
.statusIcon {
  margin-right: 10px;
}
.statusTimeStamp {
  color: rgb(0 89 132);
}
.timeStampError {
  font-size: 1.2rem;
  font-weight: 500;
}
.popover-body {
  padding-bottom: 5px !important;
}

.arrival-error {
  color: red;
  margin-top: 20px;
  margin-left: 17px;
  margin-bottom: 20px;

}