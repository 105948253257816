.productsForm {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 0.5rem;
    column-gap: 0.75rem;
    padding: 11px 11px;
    background-color: var(--eori-silver);
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 10px;
    margin-top: 15px;
    border-radius: 0.90rem;


  }



.productsTable {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 1px;
    border-radius: 5px;
    border-radius: 0.90rem;
}

.productsTable2 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 1px;
  border-radius: 5px;
  border-radius: 0.90rem;
}

.productsBtnSection {
    /* background-color: var(--eori-silver); */
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 0.90rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }
  


