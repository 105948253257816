.input-outer-container {
  display: flex;
  flex-direction: column;
  min-width: 50%;
}

.input-label {
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  line-height: 1.25rem;
  gap: 0.25rem;
}

.input-inner-container {
  border-style: solid; 
  background-color: rgba(255, 255, 255);
  border-color: rgba(209, 213, 219);
  border-radius: 0.375rem;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.input-element {
  border-style: solid;
  margin: 0;
  color: inherit;
  appearance: none;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.25rem;
  flex-grow: 1;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 0.75rem;
  font-size: 1.2rem;
  line-height: 1.45rem;
  width: 100%;
}

.no-shadow {
  box-shadow: none !important;
}

.input-element:focus, .select-element:focus, .input-focused {
  outline: none;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 0px 2px hsla(0, 0%, 0%, 0.5); /* adds a nice orange outline to focused element */
}

.inputselect-prefix {
  border-style: solid;
  margin: 0;
  color: rgb(80 80 80);
  background-color: rgb(245 245 245);
  appearance: none;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  
  border-color: transparent;
  border-radius: 0.25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: 1.2rem;
  line-height: 1.45rem;
  width: 100%;

  cursor: default;
  pointer-events: none;

  flex-basis: content;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px #ccc solid;
}

.inputselect-input {
  flex-basis: 300%;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 1px #ccc solid;
}

.inputselect-select {
  flex-basis: 100%;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-color: rgb(245 245 245) !important;
}

.select-element:enabled {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  background-color: transparent;
}

.select-element {
  max-width: 100% !important;
  border-style: solid;
  margin: 0;
  color: rgb(32 32 32);
  appearance: none;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  border-color: transparent;
  border-radius: 0.25rem;
  flex-grow: 1;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: 1.2rem;
  line-height: 1.45rem;
}

option:disabled {
  background-color: #ddd;
  color: #555;
}

.autocomplete {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 1rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border-style: solid;
  background-color: rgba(255, 255, 255);
  border-color: rgba(209, 213, 219);
  border-style: solid;
  border-radius: 0.375rem;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: text;
}

/* .autocomplete-input {
  border-style: solid;
  margin: 0;
  color: inherit;
  appearance: none;
  border-width: 1px;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.25rem;
  flex-grow: 1;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
} */

.autocomplete-input:focus {
  outline: none;
  box-shadow: none;
}

/* .ring-orange {
  outline: none;
  border-color: transparent;
  box-shadow: 0px 0px 0px 1px var(--eori-orange);
} */

.popdown-box {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;

  border-color: rgba(209, 213, 219);
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  min-height: 1rem;
  max-height: 15rem;
  overflow-y: auto;
  margin-top: 1px;
  margin-left: -1px;

  background-color: white;
}

.popdown-option {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  background-color: white;
}

.popdown-option:hover {
  background-color: rgba(243, 244, 246);
}

.popdown-option-active {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  background-color: hsl(192, 95%, 50%);
  font-weight: 500;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .25s;
  border-radius: 2rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 0.25rem;
  bottom: 0.25rem;
  background-color: white;
  transition: .25s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--eori-checkbox);
}

input:focus + .slider {
  box-shadow: 0px 0px 0px 2px hsl(0deg 0% 0% / 50%);
}

input:checked + .slider:before {
  transform: translateX(1rem);
}

.input-error-box {
  font-size: 0.75rem;
  color: red;
}


.input-filterable-button {
  background-color: white;
  color: black;
  padding: 7px;
  padding-left: 15px;
  padding-right: 30px;
  margin-top: 2px;
  font-size: 1.2rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border-radius: 0.375rem;
  border-color: rgba(209, 213, 219);
  border-width: 1px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.input-filterable-button-disabled {
  background-color: rgb(243, 243, 243);
  cursor: not-allowed !important;
  background-image: none !important;
}

.input-filterable-button-smallText {
  background-color: white;
  color: black;
  padding: 7px;
  padding-left: 15px;
  padding-right: 30px;
  margin-top: 2px;
  font-size: 0.9rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border-radius: 0.375rem;
  border-color: rgba(209, 213, 219);
  border-width: 1px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.select-element {
  max-width: 100% !important;
  border-style: solid;
  margin: 0;
  color: rgb(32 32 32);

  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  border-color: transparent;
  border-radius: 0.25rem;
  flex-grow: 1;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: 1.2rem;
  line-height: 1.45rem;
}


.input-filterable-search-container {
  box-sizing: border-box;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  background-color: white;
}


.input-filterable-search {
  box-sizing: border-box;
  font-size: 16px;
  border: none;
  width: 100%;
  padding: 8px;
}

.input-filterable-search:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-filterable-dropdown {
  position: relative;
}

.input-filterable-dropdown-content {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  width: 220px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  border-radius: 0.375rem;
}

.input-filterable-options-container {
  max-height: 250px;
  overflow-y: scroll;
}

.input-filterable-option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: default;
  border-bottom: 1px solid #ddd;
}

.input-filterable-option:hover {background-color: rgb(246, 246, 246);}

