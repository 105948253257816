.modal-dialog, .modal-content {
  border-radius: 20px!important;
}

.tab-link {
  font-weight: 500;
}

.reset-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  background: transparent;

  padding: 0.5rem 1rem;
  border-radius: 20px;
}

.reset-btn:hover {
  background: rgba(255 255 255 / 8%);
}

.reset-btn > span {
  color: hsl(0,0%,75%);
  /* font-weight: 500; */
}

.reset-btn:hover > span {
  color: hsl(0,0%,100%);
  /* font-weight: 500; */
}

.reset-btn-filters:hover {
  /* background: rgba(255 255 255 / 8%); */
}

.reset-btn-filters > span {
  /* color: hsl(0,0%,75%); */

}

.reset-btn-filters:hover > span {
  /* color: hsl(0,0%,100%); */
  
}

.reset-btn-filters > img {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-135deg);
}

.reset-btn > img {
  transition: transform 0.3s ease-in-out;
  transform: rotate(-135deg);
}

.reset-btn:hover > img {
  transform: rotate(-1turn);
}

.reset-btn-filters:hover > img {
  transform: rotate(-1turn);
}



.error-toast {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, 0);
}

.error-toast > * {
  background-color: rgb(228 227 237);
  box-shadow: 0 0 8px 4px rgba(0 0 0 / 30%);
  border-radius: 20px!important;
}

#onboard-info-form{
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-flow: row;
  row-gap: 0rem;
  column-gap: 2.75rem; 
  padding: 2.75rem;
}

#onboard-info-form > * {
  padding-left: 2.0rem;
  padding-right: 2.0rem;
}

#load-info-form {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-flow: row;
    row-gap: 0rem;
    column-gap: 0.75rem; 
    padding: 0.75rem;
}

#reporting-form {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-flow: row;
  row-gap: 1rem;
  column-gap: 0.75rem; 
  padding: 0.75rem;
}

#load-info-form > * {
    padding-left: 2.0rem;
    padding-right: 2.0rem;
}

#reporting-form > * {
  padding-left: 2.0rem;
  padding-right: 2.0rem;
}

.horizonal-border {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 15px;
    border-radius: 0.25rem;
}

.divider {
    grid-column: 1 / -1;
    width: 100%;
    background-color: #dfd8d8;
    border: none;
    height: 1px;
    margin-top: 34px;
    margin-bottom: 11px;
  }

#load-info-form .label-text {
    margin-top: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 5px;
}

#reporting-form .label-text {
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 5px;
}


#onboard-info-form .label-text {
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 5px;
}

 .section-title {
  font-size: 20px;
    margin-bottom: 20px;
    font-weight: 400;
    color: #072252;
}

.report-section-title {
  font-size: 17px;
    /* margin-bottom: 20px; */
    margin-top: 27px;
    align-self: center;
    font-weight: 400;
    color: #072252;
    width:50%;

}

p {
    color: var(--eori-blue);
}

.section-divider{
  height:1px
}

#confirmload-info {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-auto-flow: row;
    background-color: #aaa;
    border: 2px solid #aaa;
    margin: 0.25rem 0.5rem;
    gap: 1px
}

.confirmload-cell {
    display: flex;
    flex-direction: column;
    padding: 0.50rem;
    background-color: #fff;
}


 .more-info {
  font-size: 15px;
  color: var(--eori-blue);
  margin-top: 10px;
}

 .more-info img, svg {
  vertical-align: bottom;
}

.startInputs {
    color: rgb(248 125 125);
    font-size: 10px;
    margin-top: 0.5px;
    font-weight: 500;
}

.checkbox-label span {
    font-size: 0.8rem;
    color: var(--eori-blue);
    font-weight: 400;
}

.notice {
  font-size: 12px;
  font-weight: 400;
  margin-left: 2px;
  height: 21px;
  display: block;
  text-align: center;
  font-style: italic;
  color: var(--eori-blue);
}

.name {
    font-weight: 600;
    font-size: 28px;
    margin-right: 10px;
}

a {
    font-size: 14px;
}


.floating-box-header-general {
  color: #0e2554; 
  height: 60px;
  border-bottom: 0;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.25rem;
  /* padding: 0.8rem; */
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding-top: 1.3rem;
}

.floating-box-header-subheader {
  color: var(--eori-blue);
    border-bottom: 0;
    font-weight: 400;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    margin-left: 7px;
    margin-bottom: 17px;
    margin-top: 15px;
}


.floating-box-header-newload {
    color: #0e2554; 
    height: 60px;
    border-bottom: 0;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding: 0.8rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    padding-top: 0.6rem;
  }

  .floating-box-sub-header-newload {
    color: #0e2554; 
    height: 50px;
    border-bottom: 0;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    padding: 0.5rem 1rem;
}

  .md-header-text {
    font-size: 21px;
  }

  .inputs-group .input-inner-container {
    border-style: none !important
  }

  .inputs-group .input-filterable-button {
    border-style: none !important
  }

  .report-inputs-group .input-inner-container {
    border-style: none !important
  }

  .report-inputs-group .input-filterable-button {
    border-style: none !important
  }

  .epo-submit-button {
    padding: 0.5rem 0.75rem;
    width: 9rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.0rem;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 0.5rem;
    background-color: var(--eori-red);
    color: white;
  }

  .epo-submit-button:hover:not(:disabled) {
    background-color: hsl(0, 100%, 45%);
  }

  .epo-submit-button:disabled, .epo-submit-button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

  .epo-continue-button, .modal .epo-continue-button {
    padding: 0.5rem 0.75rem;
    width: 9rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.0rem;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 0.5rem;
    background-color: var(--eori-green);
    color: white;
  }

  .epo-continue-button:hover:not(:disabled), .modal .epo-continue-button:hover:not(:disabled) {
    background-color: #057a73;
  }

  .epo-continue-button:disabled, .modal .epo-continue-button:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  .blue-button-newload, .modal .blue-button-newload {
    color: #0e2554;
    background-color: #ffbe00;
    padding: 0.5rem 0.75rem;
    width: 9rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.0rem;
    cursor: pointer;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 0.5rem;
    margin-top:1.1rem;
  }

  .blue-button-newload:disabled, .modal .blue-button-newload:disabled {
    opacity: 50%;
    cursor: not-allowed;
    background-color: #ffbe00;
    border: none;
    pointer-events: auto;
  }

  .blue-button-newload.green {
    background-color: #00857d;
    color: #fff;
  }

  .blue-button-newload.green:hover {
    background-color: #007871;
  }

  .blue-button-newload.red:hover {
    background-color: #d11818;
  }

  .blue-button-newload.red {
    background-color: #ff1e1e;
    color: #fff;
  }

  .blue-button-newload:hover:not(:disabled), .modal .blue-button-newload:hover:not(:disabled) {
    background-color: hsl(44.7,100%,45%);
  }

  .back-button-reporting {
    color: #0e2554;
    background-color: #ffbe00;
    padding: 0.5rem 0.75rem;
    width: 9rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.0rem;
    cursor: pointer;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    gap: 0.5rem;
    margin-top:1.1rem;
  }

  .cancel-button {
    color: #0e2554;
    background-color: #ccc;
    padding: 0.25rem 0.25rem;
    width: 7rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 2.0rem;
    cursor: pointer;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 0.5rem;
  }
  
  .cancel-button:hover {
    background-color: #bbb;
  }

  .nl-save {
    margin: 10px 15px 20px auto;
  }


  .spacer {
    flex-grow: 1;
  }

  .floating-back-button {
    position: absolute;
    z-index: 50;
  }

  .icon-lg {
    width: 1.5rem;
    height: 1.5rem;
    font-weight: 500;

    display: grid;
    place-items: center;
}

  .icon-md {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 0.7rem;
      font-weight: 500;

      display: grid;
      place-items: center;
  }

  .icon-sm {
    width: 1rem;
    height: 1rem;
}

  #breadcrumb-tabber {
    /* background: linear-gradient(90deg, #0e2554 0%, #39548b 100%); */
    /* min-height: 800px; */
    padding: 0;
    margin-top: 0!important;
  }

  #breadcrumb-tabber .tab-control {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;


    background-color: transparent;
    color: white;
    border: none;
    border-radius: 0.25rem;

    
    padding: 0.5rem;
  }

  #breadcrumb-tabber .tab-control:hover {
    background-color: transparent!important;
  }

  #breadcrumb-tabber .tab-control.neutral > *:first-child {
    background-color: hsl(0,0%, 60%);
    color: hsl(0,0%,95%);
  }

  #breadcrumb-tabber .tab-control.neutral > *:last-child {
    color: hsl(0,0%,75%);
  }

  #breadcrumb-tabber .tab-control.active > *:first-child {
    background-color: white;
    color: var(--eori-blue);
  }

  #breadcrumb-tabber .tab-control.active > *:last-child {
    color: white;
  }

  #breadcrumb-tabber .tab-control.done > *:first-child {
    background-color: #198754;
    color: white;
  }

  #breadcrumb-tabber .tab-control.done > *:last-child {
    color: white;
  }

  .tab-header {
    display: flex;
    align-items: center;
    /* margin-left: auto;
    margin-right: auto; */
    gap: 0.25rem;
    color: var(--eori-blue);
    width: 100%;
    background-color: transparent;
  }

  .semi-fluid-container {
    padding-left: 0rem!important;
    padding-right: 0rem!important;
  }

  @media (min-width: 768px) {
    .semi-fluid-container {
      padding-left: 0.5rem!important;
      padding-right: 0.5rem!important;
    }
  }

  @media (min-width: 992px) {
    .tab-header {
      /* width: 75%;  */
    }
    .semi-fluid-container {
      padding-left: 1rem!important;
      padding-right: 1rem!important;
    }
  }

  @media (min-width: 1200px) {
    .tab-header {
      /* width: 66.7%; */
      /* margin-left: initial;
      margin-right: initial; */
    }

    .semi-fluid-container {
      padding-left: 4rem!important;
      padding-right: 4rem!important;
    }
  }
  
.inputs-group {
    background-color: #f0f4f9;
    padding-top: 0.5rem;
    width: 100%;
    max-width: 768px;
    border-radius: 0.90rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .report-inputs-group {
    background-color: #f0f4f9;
    padding-top: 0.5rem;
    width: 100%;
    /* max-width: 168px; */
    border-radius: 0.90rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
  }

  .report-inputs-group-params {
    background-color: #f0f4f9;
    /* padding-top: 0.5rem;
    
    
    border-radius: 0.90rem;
    padding-bottom: 1.5rem; */
    max-width: 391px;
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    
  }

  .onboard-inputs-group {
    background-color: #f0f4f9;
    padding-top: 0.5rem;
    width: 100%;
    max-width: 768px;
    border-radius: 0.90rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .floating-box-newload {
    background-color: #fff;
    width: 100%;
    align-self: center;
    border-radius: 20px;
    /* padding-bottom: 20px; */
  }

.tooltip-info {
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  position: relative;
  background: #02409c;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  vertical-align: top;
  margin-left: 51%;
  margin-top: 2px;
}

.send-message-name-confirm {
  color: #d11818; 
  height: 60px;
  border-bottom: 0;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.25rem;
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: center;
  font-weight: bold;
}

.medium-modal {
  width: 90% !important;
  max-width: 600px !important;
}


@media (min-width: 768px) {
    #load-info-form {
        grid-auto-flow: column;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    #confirmload-info {
        grid-template-columns: repeat(8, minmax(0, 1fr));
        
    }

    /* #reporting-form {
      grid-auto-flow: row;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  } */
}

@media (min-width: 768px) {
  #onboard-info-form {
      grid-auto-flow: column;
      grid-template-columns: repeat(3, minmax(0, 1fr));
  }

}

.datatable-button {
  margin-top: 30px;
  margin-bottom: 40px; 
  margin-right: 5px
}

.loadInfo__required_fields {
  border: 1px red solid;
}


/* Styles for customaite (CM8) */
button:disabled {
  opacity: 50%;
  cursor: not-allowed;
}

.section-group {
  padding: .6rem .8rem .8rem;
  margin: 0 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
}

.section-group > .section-group {
  padding: 0;
  margin: 0;
}

.section {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

.section-group, .section-group + .section {
  flex-basis: 49%;
}

.section-group + .section {
  align-self: stretch;
}

.title {
  margin-bottom: 5px;
}

.content {
  padding: 5px 0;
}

.form-field, .form-field .input-outer-container  {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.form-field {
  margin-bottom: .5rem;
}

.form-field .input-label, .form-field .input-inner-container {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60%;
  margin: 0;
}

.form-field .input-label, .form-field input, .form-field select, .form-field textarea {
  font-size: 1rem;
  line-height: 1.45rem;
  padding: .5rem .75rem;
}

.form-field .input-label {
  background-color: var(--eori-silver);
  border: 1px solid var(--eori-silver);
  padding: .55rem .75rem;
  font-weight: bold !important;
  transition: all 150ms ease-in-out;
  flex-basis: 38%;
}

.full-width .input-label, .full-width .input-inner-container {
  flex-basis: 100%;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border-color: transparent;
}

.input-inner-container textarea {
  border-color: rgba(209, 213, 219);
}

.documents-list, .document-line, .document-line-group  {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.documents-list {
  padding: .5rem 0 .5rem .75rem;
  gap: .5rem 0;
}

.no-docs-loaded {
  margin: 0 0 0.125rem 0;
}

.documents-list table {
  margin: 0;
}

.fileuploader {
  margin-left: 1rem;
}

.fileuploader::file-selector-button {
  background-color: var(--eori-green) !important;
  display: inline !important;
  min-width: auto;
}

.no-docs-loaded, .document-line {
  font-size: 0.9rem;
}

.document-line {
  align-items: center;
  flex: 1 1 100%;
}

.form-field > .error, .form-field > .warning {
  content: normal;
  height: auto;
} 

.form-field > .error .input-label {
  background-color: var(--error-highlight-color);
}

.form-field > .warning .input-label {
  background-color: var(--warning-highlight-color);
}

.cm8.floating-box-header-newload {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.cm8 .warning.bp-warning {
  height: 22px;
}

.textarea-with-instructions {
  margin-top: -5px;
}

.textarea-with-instructions .input-label {
  font-weight: normal !important;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: .5rem;
}