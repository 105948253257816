.load-info-text {
    background-color: #fff;
    font-size: 18px;
    padding: 2px;
    padding-left: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 30px;
}

.load-info-info-titles {
    font-size: 14px;
    color: var(--eori-blue);
}

.loads-btn {
    margin: 28px 0 20px 0;
}

.loads-btn-header {
    /* margin: 1px 20px 10px 0; */
    margin: auto;
    margin-right: 1px;
}

.btn-container {
    display:grid;
}

.floating-box-newload.loaditems{
    padding: 20px 30px 20px 30px;
    /* padding: 10px 40px 10px 10px; */
    background-color: #f0f4f9;
}

.floating-box-header-newload.loads {
    margin: 0 0 6px 0;
    padding: 0;
}


.header-group {
    /* border: solid 1px var(--eori-blue); */
    font-size: 16px;
    padding: 5px;
    line-height: 1.4rem;
}

.header-group .title{
    font-weight: 700;
    color: var(--eori-blue);
    font-size: 13px;
}

.header-group .pad-out{
    margin-right: 10px;
}

@media (min-width: 1200px) {
    .tab-header.loadlist {
      width: 30% !important;
    }
}

@media (min-width: 768px) {
    .loadlist-header {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
    }
}

.edit-cell {
    border: 1px solid rgba(0, 0, 0, 20%) !important;
    background-color: var(--eori-white) !important;
    height: auto !important;
}

.edit-cell:empty {
    border: 1px solid #ddd !important;
}

input.edit-cell {
    border: 1px solid rgba(0, 0, 0, 20%) !important;
    width: 92% !important;
    padding: 0.065rem 0.25rem 0.06rem 0.25rem !important;
    text-align: left !important;
}

select.edit-cell {
    padding: 0.18rem 0.25rem 0.177rem 0.25rem !important;
    width: 90% !important;
    text-align: left !important;
}

