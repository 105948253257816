.neenor-form {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 0.25rem;
    column-gap: 0.25rem;
    padding: 12px 12px;
    background-color: var(--eori-silver);
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 0.90rem;
    margin-top: 15px;

}

  @media (min-width: 640px) {
    .neenor-form {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }}



.neenorTable {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
   padding:12px;
    background-color: var(--eori-silver);
    border-radius: 0.90rem;
}

.neenorBtnSection {
  
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 0.90rem;
    padding-bottom: 12px;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    }

.neenor-btn {
    color: var(--eori-main-blue);
    background-color: var(--eori-yellow);
    padding: 0.5rem 0.75rem;
    width: 9rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.0rem;
    cursor: pointer;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 0.5rem;
    margin-right: 12px;
    
    }

    .neenor-btn:hover {
    background-color: hsl(44.7,100%,45%);
    }

    .neenor-details-container {
      background-color: #fff;
       width: 100%;
      align-self: center;
      border-radius: 20px;
    }

    .neenor-subtitle {
      color: #0e2554; 
      height: 30px;
      border-bottom: 0;
      font-weight:400;
      font-size: 1.2rem;
      line-height: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      margin-top: 0.8rem;
    }

    .neenor-title {
      color: #0e2554; 
      height: 40px;
      border-bottom: 0;
      font-weight: 400;
      font-size: 1.875rem;
      line-height: 2.25rem;
      padding: 1rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      margin-bottom: 2rem;
    }

    .neenor-details-section {
      margin-left: 8px;
      padding-left: 1rem;
      margin-right: 1rem;
    }

    .ml-2 {
      margin-left: 30px;
    }
    .pt-2 {
      /* margin-top: 1.5rem; */
    }

    .neenor-customer-button {
      color: #0e2554;
      background-color: #ffbe00;
      padding: 0.3rem 0;
      width: 14rem;
      border-radius: 0.9rem;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 2.0rem;
      cursor: pointer;
      border: none;
      max-width: 20ch;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      gap: 0.5rem;
      margin: 1rem 0 0 0;
    }

    .neenor-customer-button:disabled {
      color: #999;
      background-color: #ccc;
      cursor: default !important;
    }

    .neenor-checkbox {
      position: relative;
      top: 5px;
    }
    
    .neenor-checkbox-label {
      padding-left: 7px;
    }