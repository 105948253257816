/* Stepper Defaults */
.step-row-button {
  display: inline-flex;
  align-items: center;
  border: 0;
  padding: 0 1em;
}

/* Disabled */
.step-row-button:disabled {
  background-color: hsl(0, 0%, 97%) !important;
  color: hsl(0, 0%, 50%) !important;
  cursor: not-allowed !important;
}

/* Active */
.step-row-button.active, .step-row-button.done:hover, .step-row-button:not(:disabled).todo:hover {
  background-color: hsl(0, 0%, 93%);
}

/* Done */
.step-row-button.done, .step-row-button:not(:disabled).todo {
  background-color: var(--eori-white);
}

.steps-box {
  background-color: var(--eori-white);
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding-bottom: 1.5rem;
  border-radius: 0.9rem;
}

.steps-box h3 {
  padding: 2rem 1.5rem 0.5rem 1.7rem;
  margin: 0;
  font-size: 24px;
}

.step-row-button .icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.25em;
  margin-left: 0.9rem;
  margin-right: 1.1rem;
}

.step-row-button:last-child .line {
  display: none;
}

.step-row-button .circle {
  background-color: var(--eori-white);
  border: 3px solid rgb(200, 200, 200);
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  transition: all 150ms ease-in-out;
}

.step-row-button .line {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 8px);
  height: 100%;
  position: absolute;
  border-left: 3px solid rgb(180, 180, 180);
}

.step-row-button.active .circle, .step-row-button.done .circle {
  visibility: visible;
}

.step-row-button.active .circle, .step-row-button.done .circle, .step-row-button.done .line {
  border-color: #3197ee;
}

.step-row-button:disabled.done .circle, .step-row-button:disabled.done .line {
  border-color: #a9dce9;
}

.step-row-button.active .circle {
  background-color: #3197ee;
}

.step-row-button.done .circle {
  background-color: #e5f3ff;
}

.step-row-button:disabled.done .circle {
  background-color: #a9dce9;
}

.steps-box.no-title .step-row-button:first-child {
  border-radius: 20px 20px 0px 0px;
}

.step-top-banner, .epo-exports-step-banner {
  background-color: var(--eori-silver);
}

.step-top-banner {
  border-radius: 0.9rem;
  min-height: 150px;
  flex-direction: row;
}

.step-banner {
  background-color: var(--eori-silver);
  border-radius: 0.9rem;
  min-height: 150px;
}

.epo-steps-header, .epo-steps-stepper-header {
  color: var(--eori-blue);
  border-bottom: 0;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.epo-steps-stepper-header {
  font-weight: 500;
  padding: 2.075rem 0 0.875rem 1.95rem;
}

.steps-box.sticky {
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 4%;
}

.epo-exports-header {
  color: var(--eori-yellow);
  font-size:"22px";
}

.step-banner p {
  font-size: 15px;
  font-weight: 500;
}

.stepper-button {
    color: var(--eori-text);
    background-color: var(--eori-green);
    padding: 0.5rem 0.75rem;
    width: 7rem;
    border-radius: 0.9rem;
    font-size: 1.1rem;
    line-height: 1.3rem;
    cursor: pointer;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.stepper-button:disabled {
  color: #999 !important;
  background-color: #ccc !important;
  cursor: default !important;
}

.stepper-button.backstep {
  background-color: var(--eori-yellow);
  color: var(--eori-blue);
  font-weight: 500;
}

.red {
  background-color: var(--eori-red) !important;
}

.yellow {
  background-color:  var(--eori-yellow) !important;
  color: var(--eori-blue) !important;
  font-weight: 600;
}

.yellow:disabled {
  color: #999 !important;
  background-color: #ccc !important;
  cursor: default !important;
}


.grey {
  background-color:  var(--eori-grey) !important;
  color: #286380 !important;
  font-weight: 600;
}

.modal-body p:first-child {
  font-weight: 500;
}

.flags {
  gap: 1.8rem;

}

.flags img {
  border-radius: 35px;
  min-width: 100px;
  min-height: 100px;
  align-items: center;
}

.certificate {
  font-size: 1rem;
  line-height: 0.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  color:rgb(100, 98, 98);
 
}

.certificate:hover {
  font-size: 1rem;
  line-height: 0.5rem;
}

.no-graphics .step-row-button .icons {
  visibility: hidden;
  padding-left: 0;
  padding-right: 0;
  width: 0;
  margin-right: 0.2rem;
}

.no-graphics .step-row-button .circle, .no-graphics .step-row-button .line {
  border-width: 0;
}