.myModal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.myModal-content {
    width: 1700px;
    background-color: #fff;
    border-radius: 20px;
    height: 800px;
}

.myModal-header, .myModal-footer {
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.myModal-title {
    margin: 0;
}

.myModal-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    max-height: 650px;
    overflow-y: scroll;
    overflow-x: hidden;
}