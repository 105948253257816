.alert-danger{
  color: #842029;
  background-color: #f8d7da!important;
  border-color: #f5c2c7;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.main {
  display: grid;
  grid-template-columns:  1fr ;
  grid-template-rows:  0.1fr 1fr 40px;
  grid-template-areas: 
                        "  nav "
                        "   main "
                        "  .";

  /* min-height: 100vh; */
  font-size: 90%;
}
.maintextarea {
  display: grid;
  grid-area: main;
  grid-template-columns: 1fr ;
  grid-template-rows:   1fr ;
  grid-template-areas: 
                      " . "
                      "  text "
                      ;
  
}

@media (min-width: 768px) {

  .main {
    display: grid;
    grid-template-columns:  1fr ;
    grid-template-rows:  1fr ;
    grid-template-areas: 
                          "  nav  "
                          "   main"
                          ;
  
    /* min-height: 100vh; */
    font-size: 90%;
  }
  .maintextarea {
    display: grid;
    grid-area: main;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
    grid-template-rows:   40px 1fr 1fr;
    grid-template-areas: 
                        " . . . . ."
                        " . . text . ."
                        " . . . . .";
    }
  }

.maintext1 {
  grid-area: text;
}

.short{
  grid-area: short;
}
.eori{
  grid-area: eori;
}
.save{
  grid-area: save;
}

.smalltitle {
  grid-area: header;
  font-size: 300%;
  padding-bottom: 40px;
  padding-left: 30px;
  background-color: var(--background-color);
}

.main-body{
  grid-area: main;
}

.App-intro{
  grid-area: main;
  
}
.useradmin{
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;    
}

.formButton {
  justify-self: center;
  outline: none;
  border: 1px solid transparent;
  box-shadow: var(--box-shadow);
  background-color: var(--button-bg-color);
  color: var(--button-color);
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 100%;
}

.submit{
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.reset{
  margin-left: 15px;
}

/* h3 {
  margin-bottom: 1px;
  margin-top: 20px;
} */

div.main-form-bg{
  width: 100% !important;
  height:300px;
  content:url('../images/flags.jpg');
  margin-top: 30px;
  border-radius: 20px;
}

#main-form {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  padding: 0.25rem 0.75rem;
  /* color : var(--eori-blue); */
  color: white;
}

#main-form h3{
  color: var(--eori-orange);
  font-weight: 600;
  margin-bottom: 1px;
  font-size: 1.17em;
  margin-top: 16px;
 
  }
  
#main-form h1{
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #fafbd2;
  }
  .blue-button-teams {
    color: var(--eori-main-blue);
    background-color: var(--eori-yellow);
    padding: 0.25rem 0.25rem;
    width: 7rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 2.0rem;
    cursor: pointer;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 0.5rem;
  }

  .round-btn {
    border-radius: 100vh;
    font-weight: 600;
    font-size: 1.3rem;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
  }

.grey-btn {
  color: #444;
  background-color: #d8d8d8;
}

.grey-btn:hover {
  color: #444;
  background-color: #d0d0d0 !important;
}

.green-text {
  color: #00857d;
}

.green-text-alt {
  color: #00703c;
}

.green-btn {
  color: #fff;
  background-color: #00857d;
}

.yellow-btn {
  color: #fff;
  background-color: hsl(44.7,100%,45%);
}

.red-btn {
  color: #fff;
  background-color: red;
}

.red-btn:hover {
  color: #fff !important;
  background-color: rgb(231, 0, 0) !important;
}

.red-btn[disabled] {
  color: #999 !important;
  background-color: #ccc !important;
  cursor: default !important;
}

.green-btn:hover:enabled {
  color: #fff;
  background-color: #037a72 !important;
}

.grey-btn {
  color: #999;
  background-color: #ccc;
  cursor: default !important;
}

.green-btn:disabled,
.green-btn[disabled] {
  color: #999;
  background-color: #ccc;
  cursor: default !important;
}
  
  .blue-button-teams:hover:enabled {
    background-color: hsl(44.7,100%,45%);
  }

  
  .red-button-teams {
    color: var(--eori-main-blue);
    background-color: var(--eori-red);
    padding: 0.5rem 0.75rem;
    width: 9rem;
    border-radius: 0.9rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.0rem;
    cursor: pointer;
    /* margin: 20px 0px 10px 122px; */
    border: none;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 0.5rem;
    
  }
  
  .red-button-teams:hover {
    background-color: #f55;
  }

  .buttons-group {
    background-color: #f0f4f9;
    padding-top: 0.5rem;
    width: 100%;
    max-width: 768px;
    border-radius: 0.90rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
  }

#main-info-form {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  grid-auto-flow: row;
  grid-row-gap: 0rem;
  row-gap: 0rem;
  grid-column-gap: 0.75rem;
  column-gap: 0.75rem;
  padding: 0.75rem;

}

.first-img {
  content:url(../images/hands.png);
}

.second-img {
  content:url(../images/globe.jpg);
}

.third-img {
  content:url(../images/dover_term.png);
}

.forth-img {
  content:url(../images/news-export.jpg);
}

.fifth-img {
  content:url(../images/duty.jpg);
}

.sixth-img {
  content:url(../images/euro-coin.jpg);
}

.seven-img {
  content:url(../images/Trucks2.jpg);
}


.eight-img {
  content:url(../images/euro-coin.jpg);
}


.nine-img {
  content:url(../images/euro-coin.jpg);
}

.ten-img {
  content:url(../images/truck-pic.jpg);
}

.position {
  background-repeat: no-repeat;
  /* border-radius: 15px; */
  width: 100%;
  height: 100%;
  padding: 6px;
}

.map-container {
  display: grid;
  height: 500px;
  grid-auto-flow: row;
  padding: 0.75rem;
}

.small-middle-container {
margin: auto;
width: 40% !important;
}

.md-middle-container {
  margin: auto;
  width: 50% !important;
  }

  .lg-middle-container {
    margin: auto;
    width: 60% !important;
    }
    /* .env-banner {
      height: 50px;
      font-size: 30px;
   } */

.greyed-out {
  color: grey !important;
  font-style: italic;
}

.bg-grey {
  background-color: #9b8b64;
}

.eori-blue-bg {
  background-color: var(--eori-blue);
  color: white;
}

.bg-lightblue {
  background-color: #0d74f0!important;
}

.legend {
  background-color: white;
  border: 1px solid #e5e7eb;
  padding: 4px;
  border-radius: 20px;
}

.legend h2 {
  color: #0e2554;
    height: 60px;
    border-bottom: 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.25rem;
    padding: 1rem;
}

.legendLine {
  margin: 10px;
  font-size: 0.8rem;
}

.msgInternal {
    display: inline-block;
    background-color: #198754;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    margin-right: 7px;
}

.msgExternal {
  display: inline-block;
  background-color: #0d74f0;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-right: 7px;
}

.clientID-button {
  color: #fff;
  background-color: #00857d;
  padding: 0.25rem 0.25rem;
  width: 7rem;
  border-radius: 0.9rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.0rem;
  cursor: pointer;
  border: none;
  max-width: 20ch;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
}

.clientID-button:disabled,
.clientID-button[disabled] {
  color: #999;
  background-color: #ccc;
  cursor: default !important;
}

.clientID-error-text {
  color: red;
  font-size: 20pt;
  margin-bottom: 20px;
  font-weight: bold;
}

.clientID-error-section {
  padding-left:50px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.clientID-selected-row {
  font-weight: bold;
}

.warningPage-title {
  color: var(--eori-blue);
  font-size: calc(1.275rem + .3vw);
  line-height: 1.5;
}

.warningPage-header {
  padding-left: 10px;
}

.compliance-checkbox {
  position: relative;
  top: 3px;
}

.compliance-checkbox-label {
  padding-left: 7px;
}

.compliance-checkbox-container {
    margin-top: 2rem;
}
 
.checkbox-existingCustomer {
  position: relative;
  top: 5px;
}

.checkbox-existingCustomer-label {
  padding-left: 10px;
  font-weight: bold;
}

.lockedout-unchecked input[type="checkbox"]{
  border: 2px solid red;
}

.red-text-bold {
  color: red;
  font-weight: bold;
}

.activation-email-button {
  color: var(--eori-main-blue);
  background-color: var(--eori-yellow);
  padding: 0.25rem 1rem;
  border-radius: 0.9rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.0rem;
  cursor: pointer;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
}

.activation-email-button[disabled] {
  color: #999 !important;
  background-color: #ccc !important;
  cursor: default !important;
}

.cabie-company-info {
  color: #0e2554;
  font-size: 14px;
  line-height: 1.4rem;

}

.cci-header {
  font-weight: 600;
}

.fw-600 {
  font-weight: 600;
}

.general-information-field {
  background-color: transparent !important;
  font-size: 10pt !important;
}

.table-striped-foldable>tbody>tr:nth-child(4n-1) {
  background: hsla(216, 50%, 99%, 100%);
}

.table-striped-foldable>tbody>tr:hover {
  background: #f0ecec;
}

.table-striped-foldable>tbody>tr:nth-child(even) {
  /* background: var(--eori-silver); */
  background: #fff;
}

.foldable-menu-item {
  width: 100%;
  padding: 10px 20px;
  font-size: 12pt;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.foldable-menu-item:last-child {
  border: 0;
}
.foldable-menu-item:hover {
  background: #f0ecec;
}

.clock {
  font-family: monospace;
}

.clock-red {
  color: #e31818;
}

.cm8_uploadedCount{
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  padding: 0.5rem 0 0.5rem 0.75rem;
}

.btn_submitted_copy_ref{
  background-color: rgba(0, 0, 0, 0);
  color: white;
  box-shadow: none;
  display: flex;
  align-items: baseline;
  border: 1px solid white;
  justify-content: center;
  border-radius: 7px;
  padding: 10px;
}

.btn_submitted_copy_ref img{
  width: 35px;
  align-self: center;
}

.btn_submitted_copy_ref p {
  color: white;
  align-self: center;
  margin: 0 0 0 10px;
  font-weight: 600;
}