.cogModal {
    margin: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 10px;

}

.cogModal label input {
    margin-right: 10px;
}

.flex-container {
    display: flex;
    align-items: center;
}

.invoice-preview {
    font-size: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.invoice-preview > * {
    cursor: pointer;
}

.step-banner.with-img {
    display: flex;
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    gap: 1.25rem;
}

.step-banner.img {
    display: flex;
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    gap: 1.25rem;
    min-height: 0px !important;
}

.general-text {
    font-size: 0.9rem;
    font-weight: 400;
    text-align: center;
    color: var(--eori-blue);
    padding:1rem 4rem 1rem 4rem;
}

.info.docupload {
    width: 1em;
    height: 1em;
    margin-left: .4rem;
}


.tick-success-docs {
    content:url(../../images/checkmark.png);
    width: 1em;
    height: 1em;
    cursor: pointer;
    margin-left: 0.4rem;
  }

  .step-questions {
    font-weight: 500;
    color: var(--eori-blue);
    font-size:1.07rem;
  }

  .epo-dropdown {
    width: 170px;
  }

  .doc-upload-button{
    background-color: #c7dfff;
    border-radius: 0.4rem;
    margin-bottom: 10px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
  }

  .doc-upload-button:hover{
    background-color: #e1eeff
  }

  .doc-upload-bg{
    background-color: #c7dfff;
    border-radius: 0.4rem;
    margin-bottom: 10px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
  }