/* .table-scroll {
    overflow-y: auto; 
    max-height: 100px;
  } */
  
  .table {

    border-collapse: collapse;

  
  }
  
  .cell {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
    vertical-align: middle;
  }
  
  table {
    border-collapse: collapse;
  }
  th, td {
    padding: 8px;
    text-align: left;
    /* border: 1px solid #ddd; */
  }
  .heading {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #ddd;
  }
  .inner-table {
    border: 1px solid #ddd;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }