.circle-container {
    width: 24em; height: 24em;
    border-radius: 50%;
    border:1px solid #a7d8ff;
    display:grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: #f0f4f9;
    margin-left: 5%;
  }

.circle-container span {
      display: block;
      border:0.75px solid #a7d8ff;
      background:white;
      border-radius:50%;
      text-align:center;
  }

.circle-container p {
    display: block;
    color: var(--eori-blue);
    text-align: center;
    font-size: 11px;
    margin: 2px;
}

p.subtitle{
  font-size: 8px;
}

.arrival-error {
  color: red;
  margin-top: 20px;
  margin-left: 17px;
  margin-bottom: 20px;

}

.type-desc p {
    font-size: 10px;
} 
  .deg1 {grid-column:3 / span 2; grid-row: 1 /span 2} /* 1 */
  .deg2 {grid-column:1 / span 2; grid-row: 2 /span 2} /* 2 */
  .deg3 {grid-column:5 / span 2; grid-row: 2 /span 2} /* 3 */
  .deg4 {grid-column:3 / span 2; grid-row: 3 /span 2} /* 4 */
  .deg5 {grid-column:1 / span 2; grid-row: 4 /span 2} /* 5 */
  .deg6 {grid-column:5 / span 2; grid-row: 4 /span 2} /* 6 */
  .deg7 {grid-column:3 / span 2; grid-row: 5 /span 2} /* 7 */
  
  .deg2, .deg5{margin:0 -1em 0 1em;}
  .deg3, .deg6{margin: 0 1em 0 -1em}
  
  .deg1:hover,.deg2:hover,.deg3:hover,.deg4:hover,.deg5:hover,.deg6:hover,.deg7:hover {
    background:#f0f4f9;
  }

.chicken {
    content:url(./../images/poultry-leg.png);
}

.fish {
  content:url(./../images/fish-food-2.svg);
}

.veg {
  content:url(./../images/Cabbage.svg);
}

.beer {
  content:url(./../images/beer.svg);
}

.plant {
  content:url(./../images/Potted-Plant.svg);
}

.tank {
  content:url(./../images/tank.svg);
}

.cites {
  content:url(./../images/Reptile.jpg);
  width: 80%;
  margin-left: 9%;
}

.position-svg {
    background-repeat: no-repeat;
    width: 55%;
    margin-left: 25%;
}

.push-left-right {
  justify-content: space-between;
  display: flex;
}

.push-center {
  justify-content: center;
  display: flex;
}

