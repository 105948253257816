
.floating-box-table{
  -webkit-align-self: center;
  align-self: center;
  margin-top: 2rem;
  padding-left: 1rem;
  width: 100%;
  max-width: 768px;
  margin-left: 10px;
}

.tableHeaders th:nth-child(2) {
  padding-left: 10px ;
}
.tableHeaders td:nth-child(2) {
  padding-left: 10px ;
}

.tableHeaders th:nth-child(1){ 
  display:none;
}
.tableHeaders  td:nth-child(1){ display:none;}

.tableHeaders th {
  background-color: var(--eori-silver);
  color: var(--eori-main-blue);
}

table tbody {
  background-color: var(--eori-text);
}

table tbody tr:hover {
  background-color: #eee;
}

table tbody tr:nth-of-type(even):hover {
  background-color: #e1e1e1;
}

table tbody tr:nth-of-type(even).selection-hovered {
  background-color: #fee ;
}

table tbody tr.selected {
  background-color: rgb(255, 240, 240);
}

table tbody tr.selected:hover {
  background-color: rgb(245, 230, 230);
}

table tbody tr.selection-hovered {
  background-color: rgb(255, 240, 240);
}


.tableLayOut {
    display: grid;
    grid-area: main;
    grid-template-areas: " . "
                        " forms "
                        " table  "
                        " .  "
                        ;
  }
  .bigTableLayOut {
    display: grid;
    grid-template-columns: 42px 1fr  42px;
    grid-template-areas: 
                        " forms "
                        "  table   "
                        " .  "
                        ;
  
  }

  .bigTablePlace{
    grid-area: table;
  }
  
.tablePlace{
    grid-area: table;
  }
  .formPlace {
    grid-area: forms;
    display: grid;
    box-shadow: var(--box-shadow);
    grid-template-areas: " header header header header "
                         " . short  eori ."
                        
                         ". save  save .  "
                         " . . . . ";
    grid-template-rows:  50px ;
    row-gap: 10px;
    column-gap: 10px;
  }


  @media (min-width: 768px) {
    .tableLayOut {
        display: grid;
        grid-area: main;
        grid-template-rows:    0.5fr 2fr 40px ;
        grid-template-areas: 
                            " . forms . "
                            " .  table  ."
                            " . . . "
                            ;
        row-gap: 20px;
      }
    
      .bigTableLayOut {
        display: grid;
        grid-area: main;
        /* grid-template-rows:   1fr 1fr 1fr 40px ; */
        grid-template-columns: 42px 1fr  42px;
        grid-template-areas: 
                           
                            " . forms   ."
                            " . table . "
                            ;
      }
      .loadSearch {
        grid-area: forms;
        display: grid;
        box-shadow: var(--box-shadow);
        grid-template-areas: 
                             " . short  eori ."
                            
                             ". save  save .  "
                             " . . . . ";
        grid-template-rows:  50px ;
        row-gap: 10px;
        column-gap: 10px;
      }
  }
