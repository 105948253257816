.editModal {
  height: 80vh;
  max-height: 700px;
}

.dot-menu-button {
  appearance: none;
  border: none;
  background-color: transparent;
  color: rgb(70, 70, 70);
}

.dot-menu-button:hover {
  color: rgb(0,0,0);
  background-color: rgba(0,0,0, 10%);
}

.dot-menu-button::after {
  display: none!important;
}

.wide-modal {
  width: 90%;
  max-width: 800px;
}




.parent-row.has-children:hover {
  cursor: pointer;
  background-color: rgb(247, 247, 247);
}

.child-list > *:nth-child(even) {
  background-color: rgb(255, 255, 255);
}

.child-list > *:nth-child(odd) {
  background-color: rgb(250, 250, 250);
}