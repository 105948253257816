
.navbar {
  grid-area: nav;
  padding-top: 0 !important;
  padding-bottom: .08rem !important;
}

.popover {
  box-shadow: 0 2rem 2rem rgba(6, 6, 6, 0.15)!important;
}

.bg-light {
  /* background-color: #355086!important; */
}

.eoriccclogo {
  display:flex;
  /* background-color: rgba(3, 50, 73); */
  /* background: linear-gradient(90deg, #0e2554 0%, #39548b 100%); */
  width: 100%;
  flex-direction: row;
  /* justify-content: space-between; */
}
.eoriLogo {
  align-self: flex-start;
  margin: 10px 0 5px 0;
  padding: 15px 15px 10px 15px !important;
  border-radius: 20px;
}
.cabie-logo-text{
 color: #000;
}

.eoriLogo:hover {
  /* background: rgba(255, 255, 255, 0.1); */
}

.ccclogo {
  align-self:  flex-end ;
}

.navText {
  color: var(--button-bg-color);
  text-decoration: none;
  transition: margin 0.5s, color 0.25s;
  display: block;
  font-weight: 500;
  padding-left: 10px; /* this prevents "juggling" of the navText when it is animated */
  padding-bottom: 0;
}

.active-navText {
  color: var(--eori-orange);
}

.navText:hover {
  color: var(--eori-orange);
  margin-left: 10px; /* animate movement to right when hovered (on small screen) */
  margin-top: 0px;
}

.main-nav {
  margin: 10px 0 5px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--button-color);
  text-align: center;
  max-width: 1064px;
  
}

/* bootstrap likes to be important */
.dropdown-menu {
  font-size: 2rem !important;
  background-color: #f0f4f9!important;
  border-radius: 12px !important;
  border-top-left-radius: 3px!important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #fff !important;
}

.dropdown-item {
  padding: 0.2rem 1.5rem 0.3rem 1.5rem !important;
  font-weight: 400 !important;
  color: #0a447e !important;
  font-size: 16px !important;
  /* border-bottom: 0.1rem solid #00000012!important;*/
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #fff!important;
}

.dropdown-toggle::after {
  display: inline-block;
  width: .3em;
  height: .3em;
  margin: -.3em 0 0 .4em !important;
  vertical-align: middle;
  content: "";
  border: .3em solid !important;
  border-width: 0 .15em .15em 0 !important;
  transform: rotateZ(45deg)
}

@media (min-width: 992px) {
  .main-nav {
    display: flex;
    max-height: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    align-self: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.05em;
  }
  .vl {
    border-left: 0px solid black;
    height: 20px;
    margin-top: 2px;
    font-size: 1.25rem;
  }
  .navText:hover {
    color: var(--eori-orange);
    margin-left: 0px;border-bottom: red;
    /* margin-top: -10px; animate movement up when hovered (on larger screen) */
  }
  .navText {
    outline: none;
    border: 1px solid transparent;
    text-decoration: none;
    padding-bottom: 10px; /* this prevents "juggling" of the navText when it is animated */
    padding-left: 0;
  }

  .navbar-nav {
    align-items: flex-end;
    gap: 30px;
  }

  .navbar-light .navbar-nav > *:not(.navbar-brand) {
    margin-bottom: 1.5rem;
  }

  .navbar-brand {
    margin-left: 1.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-bottom-brand {
margin-top: 8px;
/* height: 90px;
    margin-top: 15px; */

}

.logo-text {
  /* color: #ffffff; */
  font-size: 10px;
  font-weight: 500;
  /* font-family: 'Work-Sans', sans-serif; */
}




}
