.home {
    display: grid;
    grid-template-columns:  1fr;
    grid-template-rows:  42px 1fr 40px;
    grid-template-areas: 
                          "  main  "
                          "  main"
                          "  . ";
    min-height: 100vh;
    /* font-size: 90%; */
    font-family: "PTSansNarrowRegular", sans-serif;
    color: hsl(0, 0%, 87.5%);
  }
  
  .maintextarea {
    grid-area: main;
  }

  .bluetext{
      color: rgb(12, 9, 86);
  }

  .back {
    grid-area: back;
    width: 100% !important;
    max-width: 1200px;
    margin-top: 40px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
  
.header-body {
  max-width: 1204px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

  .header {
      width: 52rem;
      font-size: 30px;
      padding: 0.5rem;
      margin: 1rem auto;
      border-radius: 0.5rem;
      display: inline-flex;
      justify-content: center;
  }
  .comment {
    grid-area: comment;
    font-weight: bold;
}
  .note{
    grid-area: info;
    font-size: 14px;
    font-style: italic;
  }

  .boldblue {
    color: var(--eori-blue);
    font-weight: bold;
  }

 .sub-header {
  font-size: 19px;
  font-weight: 500;
  color: #fafbd2;
 }
  
  .maintext {
    /* background-color: hsla(0, 0%, 0%, 0.2); */
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 17px;
  }
  .login {
      grid-area: login;
      display: grid;
      grid-template-areas: " register  loginbutton"
  }
  /* .loginButton{
      background:darkblue;
      margin-top: 10px;
      margin-right: 10px;
      color:rgba(197,228,47,1);
      padding: 10px 20px;
      border-radius: 25px;
      border: none;
      font-size: 120%;
  } */

  .loginButton {

    /* padding: 10px !important; */
    justify-self: end;
    outline: none;
    border: 1px solid transparent;
    /* margin: 0px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
    /* min-height: 48px; */
    background-color: var(--button-bg-color);
    color: var(--button-color);
    border-radius: 4px;
    padding: 10px 20px;
    /* font-weight: bold; */
    font-size: 100%;
    font-family: "PTSansNarrowRegular", sans-serif;
    align-self: end;
  }

  .homeSubmitButton{
  
    /* padding: 10px !important; */
    justify-self: end;
    outline: none;
    border: 1px solid transparent;
    /* margin: 0px; */
    margin-right: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
    /* min-height: 48px; */
    background-color: var(--button-bg-color);
    color: var(--button-color);
    border-radius: 4px;
    padding: 10px 20px;
    /* font-weight: bold; */
    font-size: 100%;
    font-family: "PTSansNarrowRegular", sans-serif;
    align-self: end;
  }


  .loginGrid {
      grid-area: loginbutton;
  }

  .registerGrid{
      grid-area: register;
      justify-self: end;
  }

  .homeSubmit {
      grid-area: submit;
      /* justify-self: stretch; */
  }

  /* .homeSubmitButton{
  
    background:darkblue;
    margin-top: 10px;
    margin-right: 10px;
    color:rgba(197,228,47,1);
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    font-size: 120%;
  } */

  .logo {
    grid-area: logo;
  }

  .vip {
    border-radius: 1rem;
    height: 320px;
  }


 
  
  .home-img {
    content:url(../images/truck_4.jpg);
  }
  
  .text-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
  }
  
  .position-imgs {
    background-repeat: no-repeat;
    width: 100%;
    /* padding: 1rem; */
    /* padding-bottom: 7px; */
}

  .position-box-1 {
    font-size: 13px;
    font-weight: 400;
    color: #04403d;
    margin-top: 0;
    margin-bottom: 1rem;
    height: 100%;
    padding: 0 5px 0 10px;

}

  .header-test-small {
    font-size: 16px;
    color: var(--eori--blue);
  }

.top-left {
  position: absolute; 
  top: 40px;
  left: 60px;
  color:#142b5b7a;
}

.middle-left-header {
  position: absolute;
  top: 27rem;
  left: 10.6rem;
  color: #eae9e9 ;
  font-size: 42px;
  font-weight: 600;
  opacity: 0;
  animation: fade-in 2s ease-in-out forwards;
}

.dark-block-text {
  color: #4b4a50;
}

.light-block-text {
  color: #fff;
}


.logo-link-word {
  font-weight: 600;
  font-size: 30px;
  color: rgb(227 104 35 / 67%);
  opacity: 0; 
  animation: fade-in 1s 0.2s ease-in-out forwards; 
}

.sub-line {
  margin-top: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
  opacity: 0;
}

.sub-menu{
  /* opacity: 0; 
  animation: fade-in 1s 2.2s ease-in-out forwards;  */
}
.line-one {
  animation: fade-in 2s .6s ease-in-out forwards; 
}
.line-two {
  animation: fade-in 2s 1.5s ease-in-out forwards; 
}
.line-three {
  animation: fade-in 2s 2s ease-in-out forwards; 
}
.line-four {
  animation: fade-in 2s 2.5s ease-in-out forwards;
}
.line-five {
  animation: fade-in 2s 3s ease-in-out forwards; 
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

  .container-hd {
    position: relative;
    color: white;
  }

 .top-left {
  font-size: 50px;
 }

 .home-inputs {
   padding-top: 0rem !important;
 }

 #home-info-imgs {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  padding: 1rem;
  padding-bottom: 0.3rem;
  
}

.cards {
  font-size: 14px;
}

.cards-title {
  font-size: 20px;
  font-weight: 400;
  margin: 0 10px 10px 10px;
}

#home-info-form {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  grid-auto-flow: row;
  background-color: #fff;
  width: 100%;
  align-self: center;
  border-radius: 20px;
  margin-bottom: 2rem;
}

.feeds {
  background-color: #fff;
  max-width: 1500px;
  height: 702px;  
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 20px;
}

.feeds .nav-tabs .nav-link {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px  !important;
  color: rgb(73 3 3 / 64%) !important;
  font-weight: 500;
  font-size: 14px;
}

.feeds .nav-tabs .nav-link.active {
  color:  rgb(133 129 155) !important;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 700;
  font-size: 20px;
}



.legal {
  font-size: 11px;
  color:rgb(6 74 107) !important;
}

.position {
  background-repeat: no-repeat;
  border-radius: 20px;
  width: 100%;
  height: 11.3rem;
  padding: 9px;
}

@media (min-width: 992px) {
.navbar-brand {
     align-items: left !important;
}
}

.sect-divider{
  height:6px
}

.main-text {
  font-size: 50px;
  color: #ffffff;
}

.sub-menu span {
background-color: transparent!important;
color: #fff;
padding:1rem;
font-size: 1.1rem;
}

.sub-menu span:hover {
  background-color: transparent!important;
  color: #ffbe00;
  padding:1rem;
  font-size: 1.1rem;
  cursor: pointer;
  }
