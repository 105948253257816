.blur {
    font-size: 14px;
    color: transparent;
    text-shadow: 0 0 8px #000;
  }

  .loaditems .system-reference {
    color: #3938ac !important;
    border-width: 0 !important;
    text-align: left !important;
  }

  .loaditems td.system-reference {
    margin: 0 !important;
    padding: .565rem .75rem .565rem .75rem !important;
  }

  .loaditems td > input.system-reference {
    margin: .5rem .5rem !important;
  }

  .docs-upload-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
  }
  
  .uploadBtn {
    background-color: transparent;
    border-radius: 0.4rem;
    margin-right: 10px;
    padding: 0;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    gap: .25rem;
    cursor: pointer;
  }
  
  .uploadBtn, .uploadBtn > label > input[type="file"] {
    width: 1.375rem;
    height: 1.375rem;
  }

  .uploadBtn > label {
    display: flex;
  }
  
  .uploadBtn + .rounded-circle {
    background-color: #198754;
    border-radius: 50% !important;
    color: var(--button-color);
    width: 1.25rem;
    height: 1.25rem;
    font-size: 0.7rem;
    font-weight: 500;
    display: grid;
    place-items: center;
  }