.pdf-viewer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pdf-viewer .react-pdf__Page__canvas {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 75%;
    height: auto !important;
    margin: 0.5rem auto 0 auto;
}