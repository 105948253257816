.userProfile-group {
  background-color: #f0f4f9;
  padding-top: 0.5rem;
  width: 100%;
  max-width: 768px;
  border-radius: 0.90rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.userProfile-full {
  background-color: #f0f4f9;
  border-radius: 0.90rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2.7rem;
  padding-right: 2.7rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin: 0.75rem;
}

.inline-file-selector {
  display: flex;
  align-items: stretch;
  width: 2.5rem;
  height: 2.6rem;
  background-color: transparent;

  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.inline-file-selector:focus {
  box-shadow: 0px 0px 0px 2px hsla(0, 0%, 0%, 0.5); /* adds a nice orange outline to focused element */
}

.inline-file-selector > span {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  /* color: rgba(240, 240, 240, 80%); */
  background-color: rgb(235,235,235);
  color: rgba(50, 50, 50, 80%);
  
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  cursor: pointer;
}

.inline-file-selector > span.file-selected {
  background-color: hsl(216, 98%, 52%);
  color: rgba(255, 255, 255, 95%);
}

.inline-file-selector > span.disabled {
  cursor: not-allowed;
  color: rgba(50, 50, 50, 40%);
}

.floating-box-header-userProfile {
  color: #0e2554; 
  border-bottom: 0;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.25rem;
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  margin-left: 1.6rem;
}

.floating-box-userProfile {
  background-color: #fff;
  width: 100%;
  align-self: center;
  border-radius: 20px;
}

.userProfile-info-text {
  background-color: #fff;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 10px;
  /* margin-right: 30px;
  margin-left: 20px; */
  border-radius: 0.375rem;
}

.userProfile-info-info-titles {
  font-size: 14px;
  color: var(--eori-blue);
  margin-bottom: 10px;
  /* margin-left: 20px; */
}

.userProfile-form {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-flow: row;
  row-gap: 0rem;
  column-gap: 0.75rem; 
  padding: 0.75rem;
  
}

input.userProfile-info-text, select.userProfile-info-text {
  border: 1px rgb(200, 200, 200) solid;
}

/* Below pads to match 2.0 Rem on Load Details page. 
Unsure how to exactly match without the .7 */

.userProfile-form > * {
  padding-left: 2.7rem;
  padding-right: 2.7rem;
}

.userProfile-form .label-text {
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 5px;
}
/* 
.input-group {
  background-color: white;
  border-radius: 0.375rem;

  border: 1px solid gray;
} */

.input-group input, .input-group select {
  border: 1px solid transparent;
  border-radius: 0px;
}

.userProfile-full  input:focus-visible, .userProfile-full input:focus, .userProfile-full  select:focus-visible, .userProfile-full select:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px hsla(0, 0%, 0%, 0.5);
  position: relative;
  z-index: 10;
  border: 1px solid transparent!important;
}


.input-group  div:first-child  input, .input-group  div:first-child  select {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.input-group  div:not(:last-child) input, .input-group  div:not(:last-child) select {
  border-right: 1px rgba(209, 213, 219) solid;
}

.input-group  div:last-child  input, .input-group  div:last-child  select {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

@media (min-width: 768px) {
  .userProfile-form {
      grid-auto-flow: column;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }}

/* Previous code below */

  

/* .center {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    grid-template-areas: " .  userProfile . ";
    margin-top: 105px;
} */


/* .userProfile-info-form {
  display: grid;
  grid-template-columns: 1fr;
} */


/* .load-info-text {
  background-color: #fff;
  font-size: 18px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  
}

.load-info-info-titles {
  font-size: 14px;
  color: var(--eori-blue);
} */

/* @media (min-width: 1125px) {
  .userProfile-info-form {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 20px;
      margin-left: 10px;
      padding-left: 10px;
      margin-right: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
      padding-bottom: 4px;
  }
} */


.eye-hider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border: 0;
  border-left: 1px solid hsl(0, 0%, 80%);
  align-self: stretch;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: transparent;
}

.eye-hider.show {
  color: var(--eori-green);
}

.eye-hider.hide {
  color: hsl(0, 0%, 40%);
}

.floating-box-userProfile h6 {
  margin: 0;
  font-size: 18px;
}