/* <main> on BusinessPartnerCard.js*/
.business-partner-section {
  background-color: var(--eori-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  padding: 1.5rem;
  min-width: 500px;
}

.business-partner-container {
    display: grid;
    grid-template-columns: auto 1fr;
    border-radius: 20px;
    padding: 10px;
    gap: 10px;
    border-right: 2px solid rgb(237, 237, 237);
}

.business-partner-container.no-graphics > .steps-box {
  border-radius: 20px 0 0 20px;
  border-right: 2px solid hsl(0, 0%, 93%);
  padding-right: 2.13rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0;
  margin-top: 2.075rem;
}

.steps-box .epo-steps-stepper-header {
  padding-top: 0;
}

.bp-companydetails-header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  border-bottom: 2px solid hsl(0, 0%, 93%);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.cabie-company-info {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.cabie-company-info p {
  margin: 0;
}

.cabie-company-info .label {
  font-weight: bold;
  margin-right: 10px;
}

.bp-companydetails-header-invalid{
    margin: 0 0 5px 0;
    padding: 0;
    align-content: end;
    text-align: end;
    color: var(--eori-red);
    font-weight: bold;
    text-transform: uppercase;
}

.bp-companydetails-header-invalid.warning.bp-warning {
  width: 2em;
  height: 2em;
  align-self: unset;
}

/*footer
===================================================*/
.bp-footer-buttons{
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    margin-top: 62px;
}

.bp-footer-buttons-editandsave{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
    gap: 10px;
}

.bp-footer-buttons-save-bnt {
    background-color: var(--eori-yellow);
    color: #000000;
}

button.bp-footer-buttons-back-bnt {
    background-color: var(--eori-green);
    color: var(--eori-text);
}

button.bp-footer-buttons-edit-bnt{
    background-color: var(--eori-red);
    color: var(--eori-text);
    border-radius: 10px;
}

/*table
=======================*/


/*grid
====================================*/

.bp-grid-default{
  border: 1px solid rgba(0, 0, 0, 20%);
  min-height: 30px;
  font-size: inherit;
  padding: inherit;
}

.bp-table-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns of equal width */
  gap: 0px; /* no spacing between cells */
  margin-bottom: 30px;
}

.bp-table-grid-cell {
  background-color: var(--eori-white);
  text-align: left;
  position: relative;
  padding: 0;
}

.bp-table-grid-header {
  padding: 5px;
}

.bp-table-grid-header {
  background-color: #f2f2f280;
}

.bp-table-grid-title {
  font-weight: bold;
}

.bp-table-grid input,
.bp-table-grid select {
  padding: 5px;
  margin: 0;
  border-width: 0;
  font-size: 1rem;
}

.bp-table-grid select:disabled {
  background-color: transparent;
}

.warning.bp-warning {
  align-self: normal;
  justify-self: right;
  height: 14px;
}

.bp-warning-paragraph{
  justify-self: right;
  align-self: normal;
  margin: 0;
  display: grid;
  align-content: space-around;
  cursor: pointer;
}

p.bpgrid-value, p.bp-select{
  margin: 0;
}

p.bpgrid-value{
  padding: 5px;
}

.bp-table-grid-cell > input[type=checkbox] {
  margin: 5px;
}

input.bp-grid-default, .bp-table-grid-cell > .bpgrid-value {
  line-height: 24px;
}

p.bp-select {
  width: 100%;
}

p.bp-select + p.bp-warning-paragraph {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -17px;
  background: var(--eori-white);
  padding: 9px 0 9px 9px;
  z-index: 2;
}

.bp-table-grid-cell .warning {
  content:url(/src/images/warning-reverse.svg);
}

p.bp-select .select-element
{
  min-width: 307px;
  width: 100%;
}

/*error div*/
.bp-grid-error-cell{
  border: 1px solid #FF8A84;
  min-height: 34px;
}

.bp-table-grid-cell.bp-grid-default.bp-grid-checkboxes {
  align-items: center;
  grid-template-columns: 30px auto;
}

.bp-grid-notes-img{
  min-height: 300px;
}

.bp-grid-notes-textarea{
  height: 100%;
  width: 100%;
  padding: 5px;
  margin: 0;
}

.bp-grid-default.bp-grid-notes-note{
  padding: 0;
}

.bp-section-heading {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
}

.bp-grid-default.select-element:disabled{
  background-color: var(--eori-white);
}

.view-notes-button {
  justify-self: self-start;
  padding: 4px 7px !important;
}

.bp-documents .bp-section-heading {
  margin-bottom: -30px;
}

.bpuploadDocumentModal-row {
  display: grid;
  grid-template-columns: 32% 68%;
  gap: 0px;
  padding: 0 !important;
  margin-bottom: 20px;
  min-height: 30px;
  font-size: inherit;
}

.bpuploadDocumentModal-row label, .bpuploadDocumentModal-row select {min-height: 30px;}

.bpuploadDocumentModal-row label, .bpuploadDocumentModal-row p {
  padding: 0.5rem 0;
  font-weight: 400 !important; 
}

.bpuploadDocumentModal-row p, .bpuploadDocumentModal-row label, .bpuploadDocumentModal-row input[type='text'], .bpuploadDocumentModal-row select {color: inherit;}

.bpuploadDocumentModal-row select {
  border: 1px solid rgba(0, 0, 0, 20%);
  min-width: 100%;
  font-size: 1rem;
}

.fileuploader {
  opacity: 1 !important;
  z-index: 1 !important;
  position: static !important;
  font-size: 1rem;
  padding-left: 0.5rem;
}

.fileuploader::file-selector-button {
  color: var(--eori-text);
  background-color: var(--eori-popup);
  padding: 0.5rem 0.75rem;
  border-radius: 0.9rem;
  font-size: 1.1rem;
  line-height: 1.3rem;
  border: none;
  display: block;
  margin-bottom: 5px;
  margin-left: -0.5rem;
}

.business-partner-container, .step-row-button, .bp-table-grid input, .bp-documents input, .bp-table-grid select, .modal-content, .modal-content input, .modal-title, .modal-title span {
  color: var(--eori-blue);
}

/* Widden the modal window for document preview in 'Customs & Compliance' */
.documents-preview-modal .modal-dialog {max-width: 660px;}

.documents-preview-modal .modal-dialog .modal-body p {font-weight: normal;}

.companyname-eorinumber {font-weight:  bold;}

.companyname-eorinumber .companyname {text-transform: capitalize;}

/* CABS-1392 - capiutalize certain Business Partners fields  */
.companyName, .eoriNumber,
.bp-companydetails .bp-row-0,
.bp-companydetails .bp-row-1,
.bp-companydetails .bp-row-2,
.main-contact-details .bp-row-0,
.main-contact-details .bp-row-1,
.main-contact-details .bp-row-4,
.address .bp-table-grid-cell
 {
  text-transform: uppercase;
}