/*declare variables*/
:root{
    --padding-value: 30px;
    --qty-number-size: 20px;
}

.dashboard_main_title{
    color: white;
    font-size: 1.7rem;
    margin-bottom: 0;
    text-align: left;
    font-weight: 300;
    width:55%;
}

.dashboard-main-div{
    width: 55%
}

.dashboard-floating-box{
    padding: var(--padding-value);
}

.dashboard-qty {
    font-size: var(--qty-number-size);
    color: black;
    margin: 0;
    text-align: center;
}

/*Right chevron on the dashboard's titles*/
.dashboard-chevron{
    position: absolute;
    top: 50%;
    right: 15px;
}

.total-jobs, .dashboard-breakdown{
    display: grid;
    column-gap: 0.75rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 10px;
    justify-items: center; /* Center the children horizontally */
    align-items: self-start;    
}

.total-jobs__category{
    position:relative;
    cursor: pointer;
}

.total-jobs__category:hover{
    background-color: #efe3dcde;
    box-shadow: 2px 2px 2px #f3f1f1;
    border: 1px solid #cca5a5;
}

.total-jobs__category .tooltiptext{
    visibility: hidden;
    width: 90%;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 107%;
    /* left: 50%;*/
    margin-left: -8px; 
}

.total-jobs__category .tooltiptext::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.total-jobs__category:hover .tooltiptext{
    visibility: visible;
}


.total-jobs__category, .dashboard-breakdown__section {
    margin: 0 10px;
    padding: 17px 17px;
    background-color: #ffffff;
    border: 1px solid #aeaeae;
    border-radius: 40px;
    width: 100%;
}

.total-jobs__title{
    font-size: 15px;
    margin: 0;
}

.dashboard-breakdown__section {
    background-color:#f3f1f1;
    display: grid;
    row-gap: 10px;
}

/*font attributes for the section header*/
.dashboard-breakdown__title{
    color: black;
    text-align: center;
    font-size: 15px;
    margin: 0;
}

.dashboard-breakdown__status {
    background-color: white;
    border-radius: 30px;
    display: grid;
    justify-content: center;
    cursor: pointer;
}

.dashboard-breakdown__status__text{
    color: var(--eori-blue);
    text-align: center;
    margin: 0;
    font-size: 14px;
}

.dashboard-breakdown__information{
    background-color: white;
    border-radius: 30px;
    display: grid;
    justify-content: center;
    /* cursor: pointer; */
    border: 1px solid #aeaeae;
    padding: 0.2rem;
}

.dashboard-breakdown__information:hover{
    background-color: #efe3dcde;
    border: #cca5a5;
    border: 1px solid #cca5a5;
    /* box-shadow: 2px 2px 2px #f3f1f1 */
}

.total-jobs__p{
    font-weight: normal;
}

/* add a media query for screens with width less than 1400px */
@media screen and (max-width: 1300px){
    .dashboard-main-div{
        width: 90%;
    }
}

.full-width-container.custom-margin {
    margin-top: .6rem; 
}

.floating-box.custom-margin {
    margin-top: 1.2rem; 
}