.commodity-code-details,
.commodity-code-details .manage-commodity-codes,
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.commodity-code-details .floating-box-header,
.action-buttons {
  width: 100%;
}

.action-buttons {
  justify-content: space-between;
}

.commodity-code-details .manage-commodity-codes {
  width: 90%;
}

.manage-commodity-codes .input-outer-container {
  min-width: auto;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0.5rem 0;
  width: 100%;
}

.manage-commodity-codes .input-outer-container > .mapping-info {
  justify-content: space-between;
  margin: 0 0 1rem 0;
  width: 100%;
}

.manage-commodity-codes .input-outer-container > label,
.manage-commodity-codes .input-outer-container > .mapping-info > .label {
  font-size: 1rem;
  width: 28%;
}

.manage-commodity-codes .input-outer-container > label > span {
  padding-top: 0.75rem;
}

.manage-commodity-codes .input-outer-container > .input-inner-container,
.manage-commodity-codes .input-outer-container > p > .value {
  background-color: transparent;
  border: none;
  margin: 0;
  width: 70%;
}

.manage-commodity-codes .input-outer-container > .input-inner-container input:not([type=checkbox]),
.manage-commodity-codes-modal .modal-content .input-element {
  background-color: var(--eori-white);
  border-color: rgba(209, 213, 219);
  border-radius: 0;
  border-width: 1px;
}

.manage-commodity-codes-modal .modal-content .input-element[data-error] {
  background-color: rgb(245, 237, 237);
}

.manage-commodity-codes .input-outer-container > .input-inner-container input[type=checkbox] {
  margin: 0;
  top: 0;
}

.manage-commodity-codes .input-outer-container p {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.manage-commodity-codes .mapping-info-group {
  flex-direction: column;
}

button.manage-commodity-codes {
  width: auto;
  max-width: none;
}

.manage-commodity-codes-modal .modal-content .customer-heading {
  margin: 20px 0 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.manage-commodity-codes-modal .modal-content .customer-heading .label {
  font-weight: 700;
  margin-right: 20px;
  width: 100px;
}

.manage-commodity-codes-modal .modal-content td.hsCode,
.manage-commodity-codes-modal .modal-content td.description {
  padding-top: 18px;
}

.manage-commodity-codes-modal .modal-content td.hsCode,
.manage-commodity-codes-modal .modal-content td.commodityCode {
  width: 150px;
}

.manage-commodity-codes-modal .modal-content td.description {
  text-transform: uppercase;
  width: 400px;
}

.manage-commodity-codes-modal .floating-box-header-newload {
  flex-direction: row;
  font-size: 1rem;
  margin: 0;
}

.error-message {
  color: var(--eori-red);
  display: inline-block;
  margin: 0 1rem 0 0;
}

.error-message::first-letter {
  text-transform: uppercase;
}

.manage-commodity-codes-modal .modal-content .customer-heading span,
.manage-commodity-codes-modal .modal-content .user-notification {
  color: var(--bs-modal-color);
}

.manage-commodity-codes-modal .modal-footer {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.manage-commodity-codes-modal .modal-footer .apply-changes-section {
  justify-content: flex-end;
  width: auto;
}

.manage-commodity-codes-modal .modal-footer .apply-changes-section .blue-button-newload {
  margin: 0;
}