.teamTable  th:nth-child(1){ 
    display:none;
  }

.teamTable td:nth-child(1){ display:none;}

.teamBackground{
    margin-top: 30px;
    background-color: white;
    border-radius: 20px;
}

/* teamHeader edited to match New Load */

.teamHeader {
    color: var(---eori-main-blue);
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding: 1rem;
    margin-bottom: .5rem;
}

/* teamForm edited to match New Load */
.teamForm {
  background-color: var(--eori-silver)!important;
}

/* teamsForm edited to match New Load, -px for margin left required for input aligning */
.teamsLabel {
font-weight: 400;
font-size: 20px;
color: #072252;
margin-bottom: 10px;
padding-left: 10px;
padding-right: 10px;
margin-top: 10px!important;
margin-left: -14px;
}


.teamInputs {
border-style: solid;
appearance: none;
border-width: 1px;
border-color: transparent;
border-radius: 0.25rem;
outline: 2px solid transparent;
outline-offset: 2px;
font-size: 1.2rem;
line-height: 1.45rem;
/* max-width: 80%; */

padding: 10px;
/* margin-left: 20px; */

}
.teamButton {
    margin-bottom: 20px;
    margin-right: 20px;
}

/* Button styled to match New Load */
.adminButton {
  color: #0e2554!important;
  background-color: #ffbe00!important;
  padding: 0.5rem 0.75rem!important;
  width: 9rem!important;
  border-radius: 0.9rem!important;
  font-weight: 600!important;
  font-size: 1.25rem!important;
  line-height: 2.0rem!important;
  cursor: pointer!important;
  border: none!important;
  margin-bottom: 5px;
  margin-left: 30px;
}



/* .teamTable {
  /* margin-left: 10px; */



  /* Use of Important required in order to align table margins */
.adminTable {
  margin-right: 0px !important;
  margin-left: 0px !important;
}


.btn-custom {
    background-color: var(--eori-yellow);
    color: var(--eori-main-blue);
    border-radius: 0.9rem;
  }
  .btn-flat {
    background-color: var(--eori-yellow);
    color: white;
  }
  
  .btn-light {
    margin: 10;
    border-radius: 0.9rem !important ;
    /* overflow-wrap: break-word; */
    color: var(--eori-main-blue) !important;
    background-color: var(--eori-yellow) !important;
  }
  
  .textColor {
    color: white
  }
  
  .bgColor {
    background: purple
  }