#register-contents {
    padding: 1rem;
    display: grid;
    row-gap: 0.25rem;
    column-gap: 0.5rem;
}
.floating-box-register {
    background-color: white;
    border-width: 1px;
    border-style: solid;
    align-self: center;
    width: 100%;
    max-width: 640px;
    border-radius: 20px;
}

.register-step-header {
    padding: 0 1rem;
    margin-bottom: 1rem;
    font-size: 1.875rem;
    font-weight: 400;
    color: #0e2554;
}

.register-info {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.registrationText{
    color: var(--eori-blue);
    font-weight: 500;
    font-size: 1.05rem;
}
.registrationCheck{
    align-self: center;
    justify-self: flex-end;

}

.sub-heading {
    font-weight: 400 !important;
    color: var(--eori-blue);
    font-size: 0.875rem;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
  }

  .sub-heading-smartPack {
    font-weight: 400 !important;
    color: var(--eori-blue);
    font-size: 0.875rem;
    margin-right: 1.1rem;
  }
  
  .password-error {
    color: red;
    margin-top: 20px;
    margin-left: 0px;
  }
  .password-error2 {
    color: red;
    margin-top: 20px;
  }
.approveRejectButton {
    padding: 0.5rem 0.75rem;
    width: 9rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.0rem;
    border: none;
    max-width: 20ch;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    color: #ffffff;
}

.approveRejectButton:disabled {
opacity: 50%;
}

@media (min-width: 640px) {
    #register-contents {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

/* Confirmation Modal Styling */

.wide-modal {
    width: 60%;
    max-width: 800px;
  }