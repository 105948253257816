.dataEnrichment input.input-element {
    font-size: 1rem;
}

.measureInput .input-element {
    font-size: 0.8rem !important;
}

.measureInput .col {
    flex: none
}

.blur {
    filter: blur(3px);
}

.centreDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
    color: var(--eori-blue);
    font-size: 1.2rem;
}

.spinner-border {
    width: 2.5rem;
    height: 2.5rem;
    border: .25rem solid #3b6ac7;
    border-right-color: transparent;
    margin-bottom: 1.5rem;
}