:root {
  --button-bg-color: rgba(3, 50, 73);
  --button-color: white;
  --box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  --background-color: rgba(249, 250, 251);
  --eori-orange:rgba(255, 128, 56);
  --eori-blue: rgba(3, 50, 73);
  --eori-grey: #A9BED4;
  --eori-text: white;
  --eori-silver: #f0f4f9;
  --eori-main-blue: #0e2554;
  --eori-breadcrumb-line: rgb(206 183 25);
  --eori-yellow: #ffbe00;
  --eori-popup:#5bace9;
  --eori-checkbox: #4dd7fa;
  --eori-green: #00857d;
  --eori-red : #ff1e1e;
  --eori-dark-grey: #272626;
  --eori-white: #fff;
  --eori-checkbox: var(--eori-green);
  --warning-highlight-color: #FFC957;
  --warning-highlight-icon: #FFC957;
  --error-highlight-color: #FFB4B0;
  --error-highlight-icon: url(/src/images/warning.svg);
  --valid-highlight-icon: url(/src/images/green-tick-box.svg);
}

.dark-text-theme {
  color: var(--eori-dark-grey);
}

.light-text-theme{
  color:var(--eori-white)
}

.sr-only {
  display: none;
}

label, input {
  cursor: inherit;
}

.modal-title {
  color: var(--eori-blue) !important;
}

.nav-tabs {
  gap: 8px;
  padding: 0 4px;
}

.nav-tabs .nav-link {
  color: var(--eori-blue) !important;
}

.nav-tabs .nav-link:focus:not(.active), .nav-tabs .nav-link:hover:not(.active) {
  border-color: #ccc #ccc #dee2e6 !important;
  background-color: rgba(100, 100, 100, 0.05) !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--eori-blue) !important;
  font-weight: 500;
}

.nav-tabs .nav-link {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  font-size: 16px;
}

::selection {
  background-color: var(--eori-orange);
  color: white;
}

::-webkit-scrollbar-track {
  background-color: rgba(209, 213, 219);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(107, 114, 128);
  border-radius: 0.125rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(75, 85, 99);
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.full-width-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  margin-top: 30px;
}

.floating-box {
  background-color: var(--eori-silver);
  border: 1px solid #e5e7eb;
  -webkit-align-self: center;
  align-self: center;
  margin-top: 2rem;
  width: 100%;
  max-width: 768px;
  border-radius: 20px;
}

.align-left { 
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 768px;
}

.floating-box-header {
  color: #033249;
  color: var(--eori-blue);
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  background-color: var(--eori-silver);
  padding: 1rem;
  margin-bottom: .5rem;
  border-radius: 20px;
}

.modal-footer {
  color: rgba(31, 41, 55);
  background-color: rgba(243, 244, 246);
  border-top: 1px solid rgba(229, 231, 235);
  padding: 0.25rem 1rem;
  margin-top: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  
  /* Collapse elements vertically on smaller screens */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-eoriblue {
  color: var(--eori-blue);
}

.text-eorired {
  color: var(--eori-red);
}

.text-eoriorange {
  color: var(--eori-orange);
}

.bg-eoriorange {
  background-color: var(--eori-orange);
}

.bg-eoriorange {
  background-color: var(--eori-orange);
}

.breadcrumb-line {
  background-color: var(--eori-breadcrumb-line);
  height: 2px;
}


.bg-eoriblue {
  background-color: var(--eori-blue);
}

.info {
  content:url(./images/info-blue.svg);
  width: 1.25em;
  height: 1.25em;
  cursor: pointer;
}

.question {
  content:url(./images/question.png);
  width: 1.52em;
  height: 1.52em;
  cursor: pointer;
}

.important-info {
  content:url(./images/important-blue.svg);
  width: 1.25em;
  height: 1.25em;
  cursor: pointer;
}

.tick-success {
  position: relative;
  content:url(./images/checkmark.png);
  width: 1.25em;
  height: 1.25em;
  cursor: pointer;
}

.tick-success-large {
  position: relative;
  content:url(./images/checkmark.png);
  width: 4em;
  height: 4em;
  cursor: pointer;
}

.success-result-tick {
  position: absolute;
  top: 50%;
  left: 50%;
  content:url(./images/checkmark.png);
  transform: translate(-50%, -50%);
  width: 3em;
  height: 3em;
  cursor: pointer;
}

.failed-result-cross {
  position: absolute;
  top: 52%;
  left: 50%;
  content:url(./images/cross.svg);
  transform: translate(-50%, -50%);
  width: 3em;
  height: 3em;
  cursor: pointer;
}

.success-result-circle {
  position: relative;  
  border-radius: 50%;
  height: 80px;
  width: 80px;
  border: 2px solid rgb(2, 6, 2);
}

.cursor-normal {
  cursor: default !important;
}

.warning, .document {
  width: 1.25em;
  height: 1.25em;
  cursor: pointer;
}

.warning {
  content: var(--error-highlight-icon);
}

.document {
  content:url(./images/documents.svg);
}

.important-info.controlled{
  width: 4em;
}

.popover {
  border: 1px solid #d7d7d7 !important;
}

.popover-body h6, h5 {
  color:  var(--eori-popup) !important;
}

.text-popup {
  color:  var(--eori-popup) !important;
}

.popover-body p {
  color: rgb(0 89 132) !important;
}

.bg-gray {
  background-color: hsl(0, 0%, 60%);
}

.text-gray {
  color: hsl(0, 0%, 60%);
}

.horizontal-line {
  height: 2px;
}

.divider {
  grid-column: 1 / -1;
  width: 100%;
  background-color: #bbb;
  border: none;
  height: 1px;
}

.doc-add-btn {
  border: none;
  align-self: start;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-radius: 20px;
  margin-top: 2.7rem;
  background-color: #ffe048;
  color: #0e2554;
  font-size: 1.1rem;
  /* font-weight: 500; */
  width: 10rem
}


.doc-add-btn:hover {
  background-color: hsl(50, 74%, 64%);;
  /* color: hsl(200, 92%, 30%); */
}


.invoice-add-btn {
  border: none;
  background: transparent;
  align-self: start;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  border-radius: 10px;
}

.invoice-add-btn:hover {
  background-color: hsla(0, 0%, 90%, 70%);
}

.restore-btn {
  border: none;
  background: transparent;
}

.restore-btn:hover {
  background-color: hsla(0, 0%, 90%, 70%);
}

.invoice-delete-icon {
  color: hsl(0, 70%, 53%);
  border: none;
  background-color: transparent;
}

.invoice-delete-icon:hover {
  color: hsl(0, 100%, 60%);
  background-color: hsla(0, 0%, 30%, 10%);
}

.checkbox-label, .radio-label {
  display: inline-flex;
  align-items: center;
  column-gap: 2px;
  background-color: var(--eori-silver);
}

input:disabled, select:disabled, textarea:disabled, input[type="file"]:disabled ~ * {
  background-color: rgb(247,247,247);
  cursor: not-allowed;
}

select:disabled {
  /* color: rgb(247,247,247)!important; */
}

[type="radio"] {
  appearance: none;
  height: 1.3rem;
  width: 1.3rem;
  background-color: #fff;
  border-color:  #3197ee; 
  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
}

/* [type="radio"]:focus, [type="checkbox"]:focus {
  box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px var(--eori-checkbox);
} */

[type="radio"]:checked {

  background-color: #3197ee;
  box-shadow: inset 0 0 0 3px #f4f4f4;

  /* color: var(--eori-checkbox);
  background-color: var(--eori-checkbox);
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E"); */
}

/* .modal-content > * {
  overflow: hidden;
} */

.modal-content {
  overflow: auto;
}

::placeholder { 
  color: hsl(0, 0%, 65%);
  font-style: italic;
}

:-ms-input-placeholder {
  color: hsl(0, 0%, 65%);
  font-style: italic;
}

::-ms-input-placeholder {
  color: hsl(0, 0%, 65%);
  font-style: italic;
}

[type="checkbox"] {
  appearance: none;
  height: 1.2rem;
  width: 1.2rem;
  background-color: #fffcfc;
  border-color: var(--eori-checkbox);
  border-width: 2px;
  border-style: solid;
  margin-right: 5px;
  transition: all 150ms ease-in-out;
}

[type="checkbox"]:checked {
  color: var(--eori-checkbox);
  background-color: var(--eori-checkbox);
  border: 2px;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.label-text {
  font-weight: 500;
  color:var(--eori-blue);
}

.eori-logo {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  cursor: pointer;
}

/* .eori-logo:hover {
  opacity: 90%;
} */
*:focus-visible {
  outline: none;
  box-shadow: 0 0 1px 4px rgba(117 109 183 / 40%);
  z-index: 5;
}

input:focus {
  /* box-shadow: none; */
}

.blue-button {
  color: #0e2554;
  background-color: #ffbe00;
  padding: 0.5rem 0.75rem;
  /* min-width: 16ch; */
  border-radius: 0.9rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 2.0rem;
  /* cursor: pointer; */
  margin: 10px 0px 10px 2px;
  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
  max-width: 12ch;
  justify-content: center;
  display: flex;
}

.red-text {
  color: #d22;
}

.save-btn:disabled {
  cursor: not-allowed !important;
  opacity: 70%;
}

.invoice-file-button {
  color: var(--eori-green);
  padding: 0.5rem;
  cursor: pointer;
}

.invoice-file-button:hover {
  background: rgba(50,50,50, 0.1);
}

.red-button {
  color: white;
  background-color: #d22;
  padding: 0.5rem 0.75rem;
  min-width: 8ch;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
  margin: 0.5rem 0;
  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
}

.red-button:hover {
  background-color: #f55;
}

button:focus{
  outline:none !important;
  }

  /* button:hover{
    background-color:#ecb206 !important;
    } */

.gray-button {
  color: rgba(55, 65, 81);
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
  margin: 0.5rem 0;
  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
  
  /* box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05); */
}

.gray-button:hover {
  background-color: rgba(229, 231, 235);
  color: rgba(156, 163, 175);
}

.button-text-link {
      background: none !important;
      border: none;
      padding: 0 !important;
      text-decoration: underline;
      cursor: pointer;
      font-size: 1rem;
      color: var(--eori-green);
}

.userprofile-content {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.orange-text {
  color: var(--eori-orange);
}

.gray-text {
  color: #555;
}

.bold {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

#team-members-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 0.25rem;
  column-gap: 0.25rem;
  padding: 0.125rem 0.5rem;
}

#neenor-form {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 0.25rem;
  column-gap: 0.25rem;
  padding: 0.125rem 0.5rem;
}

#products-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  padding: 0.25rem 0.75rem;
}

#listLoads-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  padding: 0.25rem 0.75rem;
}

/* #userAdmin-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  padding: 0.25rem 0.75rem;
} */

#userdetails-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 0.5rem;
  column-gap: 0.75rem;
  padding: 0.25rem 0.75rem;
}

.colspan-full {
  grid-column: 1 / -1;
}

.column-shrink {
  width: 1px;
  white-space: nowrap;
}

.page-item.active .page-link {
  background-color: var(--eori-green) !important;
  border: 1px solid var(--eori-green) !important;
  color: var(--eori-white) !important;
}

.page-item .page-link:focus {
  box-shadow: none;
  background: var(--eori-white);
}

.page-link {
  border: 1px solid #dee2e6 !important;
  color: var(--eori-green) !important;
}
.table>thead {
  text-transform: uppercase;
}

.table>thead>tr>th{
  color: var(--eori-blue);
  font-size: 14.4px;
  background-color: var(--eori-white);
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(221, 221, 221);
  border-top: 1px solid rgb(221, 221, 221);
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: hsla(216, 50%, 99%, 100%) !important;
}

.table-striped>tbody>tr:nth-of-type(even) {
  --bs-table-accent-bg: hsla(216, 50%, 97%, 100%) !important;
}

.uploadCell {
  height: 1px;
  cursor: pointer;
  padding: 0;
}

*:nth-child(odd) > .uploadCell:hover {
  background-color: #d5d5d5;
}

*:nth-child(even) > .uploadCell:hover {
  background-color: #c5c5c5;
}

.tableButton {
  width: 100%;
  height: 100%;
  border: none;
}

.external-table-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00857d;
  border: none; 
  background-color: transparent;
}

.external-table-link-disabled {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a3aaa9;
  border: none; 
  background-color: transparent;
  pointer-events: none;
}

.external-table-link:hover {
  background-color: #ccc;
  color: #00857d;
}


.external-table-link-reports {
  /* width: 2.2rem;
  height: 2.2rem; */
    /* width: 25%;
  height: 25%; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00857d;
  border: none; 
  background-color: transparent;
}

.external-table-link-reports-disabled {
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: end;
  align-items: center;
  color: #a3aaa9;
  border: none; 
  background-color: transparent;
  pointer-events: none;
}

.external-table-link-reports:hover {
  /* background-color: #ccc; */
  /* width: 3rem;
  height: 3rem; */
  color: #00857d;
}

.view-table-header-reports {
  /* width: 2.2rem;
  height: 2.2rem; */
    /* width: 25%;
  height: 25%; */
  
  display: flex;
  justify-content: end;
  align-items: center;
  /* color: #00857d; */
  border: none; 
  background-color: transparent;
}

.progress-bar.bg-warning {
  background-color: #ebc247 !important;
}

.superwide-modal {
  width: 90% !important;
  max-width: 1500px !important;
}

.help-widget {
  border: none;
  border-radius: 100vh;
  background-color: hsl(0, 0%, 95%);
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsl(220, 41%, 43%);
  width: 2.5rem;
  height: 2.5rem;
  transition: background-color 0.15s ease-in;
}

.help-widget:hover {
  background-color: #ffbe00;
}

.filter-icon {
  display: inline-flex;
  alignItems: center;
  padding: 0.3em;
  border-radius: 5px;
  cursor: pointer;
  color: gray;
}

.filter-icon:hover {
  background-color: hsla(0, 0%, 50%, 25%)
}

.filter-icon.active {
  background-color: hsla(0, 0%, 0%, 7%);
  color: var(--eori-green) !important;
}

.filter-icon.active:hover {
  background-color: hsla(0, 0%, 50%, 25%);
}


.sort-btn {
  font-size: 12px !important;
  border: 1px solid hsla(0, 0%, 0%, 20%);
  border-radius: 0.75em;
  padding: 0.25em 1em;
  background-color: hsla(0, 0%, 0%, 5%);
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}
.sort-btn.active {
  background-color: var(--eori-popup);
  color: white;
}

.sort-btn.active:hover {
  background-color: var(--eori-popup) !important;
}

.sort-btn:hover {
  background-color: hsla(0, 0%, 0%, 10%);
}

.table-search-btn {
  color: rgb(255, 255, 255);
  border-radius: 10px;
  gap: 0.25rem;
  display: flex;
  padding: 0.25rem 0.75rem;
  background-color: var(--eori-green);
  font-size: 1.05rem;
  font-weight: 500;
}

.table-search-btn:hover {
  opacity: 85%;
}

.per-page-select {
  background-color: rgba(255, 255, 255, 0.75);
  /* border-radius: 10px; */
  background-color: transparent;
  gap: 0.25rem;
  display: flex;
  align-items: stretch;
  padding: 0 0 0 0.5rem;
  color: var(--eori-blue);
}

.per-page-select > span {
  align-self: center;
}

.per-page-select > select {
  border: none;
  background-color: transparent;
  padding: 0.25rem 0.3rem 0.25rem 0;
  color: var(--eori-blue);
  text-align: right;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.per-page-select > select:focus{
  box-shadow: none;
}

.invoice-modal {
  height: 90vh;
}

@media (min-width: 640px) {
  #neenor-form {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .floating-box-header {
    flex-direction: row;
    column-gap: 0.5rem;
  }

  .modal-footer {
    flex-direction: row-reverse;
    column-gap: 0.5rem;
  }
}

.input-smaller-text input {
  font-size: 1rem;
}

.light-green-btn {
  background-color: #d8e9dc !important;
}

.system-ref {
  color: #3938ac;
  text-align: center !important;
  font-family: monospace;
  font-size: 1.03rem;
}


.full-page-container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.copied-text {
  background-color: rgb(231, 229, 229);  
}

.docs-container {
  display:flex;
  justify-content: space-between; 
  background: #fdfdfd;
  padding: 0.2rem;
  /*margin: 3rem auto; */
  border-radius: 0.2rem;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative; 
}

.docs-container div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.docs-container .docs-item{
  border-right: 1px solid #ccc; 
  padding: 5px; 
}

.xsmall {
  width: 20rem;
}

.docs-container input {
  width: 50px;
  height: 26px;
  margin: 0 auto;
  margin-left: 10px;
  border: none;
  border:solid 1px #ccc;
  border-radius: 5px
}

/* Define styles for the disabled button */
.docs-container .btn:disabled {
  cursor: not-allowed;
  border: none;
}


/* Fancy checkbox - https://getcssscan.com/css-checkboxes-examples - example 30 - */
.fancy-checkbox .checkbox {
  --bg: var(--eori-white);
  --brdr: hsl(0, 0%, 75%);
  --brdr-actv: var(--eori-green);
  --brdr-hovr: hsl(0, 0%, 50%);
  --dur: calc((var(--size, 2)/2) * 0.6s);
  display: inline-block;
  width: calc(var(--size, 1) * 22px);
  position: relative;
  margin: 6px 5px 0 5px;
}

.fancy-checkbox .checkbox:after {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

.fancy-checkbox .checkbox > * {
  position: absolute;
}

.fancy-checkbox .checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background-color: var(--bg);
  border-radius: calc(var(--size, 1) * 4px);
  border: calc(var(--newBrdr, var(--size, 1)) * 2px) solid;
  color: var(--newBrdrClr, var(--brdr));
  outline: none;
  margin: 0;
  padding: 0;
  transition: all calc(var(--dur) / 3) linear;
}

.fancy-checkbox .checkbox input:hover {
  --newBrdrClr: var(--brdr-hovr);
}

.fancy-checkbox .checkbox input:checked {
  --newBrdrClr: var(--brdr-actv);
  transition-delay: calc(var(--dur) /1.3);
}

.fancy-checkbox .checkbox input:checked + svg {
  --dashArray: 16 93;
  --dashOffset: 109;
}

.fancy-checkbox .checkbox input:disabled {cursor: not-allowed;}
.fancy-checkbox .checkbox input:disabled:hover {--newBrdrClr: var(--newBrdr);}


.fancy-checkbox .checkbox svg {
  fill: none;
  left: 0;
  pointer-events: none;
  stroke: var(--stroke, var(--border-active));
  stroke-dasharray: var(--dashArray, 93);
  stroke-dashoffset: var(--dashOffset, 94);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  top: 0;
  transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
}

.fancy-checkbox .checkbox svg,
.fancy-checkbox .checkbox input {
  display: block;
  height: 100%;
  width: 100%;
}

.fancy-checkbox .checkbox + svg symbol path {
  color: var(--eori-green);
}

/* CABS-1077 - Front-End - CABIE / EPO Loads screens to only allow block capitals  */
.countryOfOrigin,
.formattedText input.input-element,
.load-product-information .commodity-table .cell,
.load-product-information .commodity-table input,
.load-product-information tbody td
{
  text-transform: uppercase;
}

/* CABS-1738 - sytle rules for new message on the CABIE User Registration page */
main > .guide-intro {
  --bs-bg-opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1070px;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  border-radius: 20px;
  padding: 1.5rem 1.5rem .5rem 1.5rem;
}
.guide-intro p {
  font-weight: bold;
}