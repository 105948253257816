.txt-container{
    padding: 1rem 1rem 0 1rem;
}

.txt-container h6 {   
    color: var(--eori-popup);
}
 
.txt-container p{
    font-size: 14px;
    color: rgb(0 89 132) !important;   
}
