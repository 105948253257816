.spinner {
  transition: transform 1s;
  animation: spinning 1s linear infinite;
  color: var(--eori-blue);
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.spinner-text {
  color: var(--eori-main-blue);
  margin-top: 17px; 
}